import { Observable, of } from "rxjs";
import { PaginationResponse, withTransaction } from "@datorama/akita";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { isArray } from "lodash-es";

import {
  ApiResponse,
  PAGINATION_COUNT_ONLY_PARAM,
  PAGINATION_PAGE_PARAM,
  PAGINATION_PER_PAGE_PARAM,
  PAGINATION_SEARCH_PARAM,
  _delete,
  get,
  post,
  put,
} from "lib/api";
import { Credential } from "state/credential/interface";
import { CredentialState } from "state/session/interface";
import { breadcrumbService } from "services/Breadcrumb";
import { credentialStore } from "./store";
import { domainService } from "services/Domain";
import { sessionQuery } from "state/session/query";
import { sessionService } from "state/session/service";

import * as apiConstants from "constants/api";
import * as credentialConstants from "constants/credentials";

export class CredentialService {
  fetch(config: CredentialState): Observable<PaginationResponse<Credential>> {
    let url = `${domainService.apiRoot}/${credentialConstants.CREDENTIALS}`;

    url += `?${PAGINATION_PAGE_PARAM}=${config.currentPage}&${PAGINATION_PER_PAGE_PARAM}=${config.perPage}`;

    if (config.countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }
    if (config.search) {
      url += `&${PAGINATION_SEARCH_PARAM}=${encodeURIComponent(config.search)}`;
    }

    if (config.workingOnly) {
      url += `&${credentialConstants.WORKING_ONLY}=true`;
    }

    return get<PaginationResponse<Credential>>(url).pipe(
      tap((response) => {
        credentialStore.upsertMany(response.data);
        breadcrumbService.set(response.data, "credential");
      }),
    );
  }

  fetchOne(credentialId: string): Observable<Credential> {
    const url = `${domainService.apiRoot}/${credentialConstants.CREDENTIALS}/${credentialId}`;
    return get<ApiResponse<Credential>>(url).pipe(
      map((response) => response.data),
      tap((credential) => {
        breadcrumbService.set([credential], "credential");
      }),
    );
  }

  createCredential(credential: Partial<Credential>): Observable<Credential> {
    return post<ApiResponse<Credential>>(
      `${domainService.apiRoot}/${credentialConstants.CREDENTIALS}`,
      { body: credential },
    ).pipe(map((response) => response.data));
  }

  createCredentialWithForm(
    credential: Partial<Credential>,
    credentialFormId: string,
  ): Observable<Credential> {
    return post<ApiResponse<Credential>>(
      `${domainService.apiRoot}/${credentialConstants.CREDENTIALS}/${credentialConstants.WITH_FORM}?credentialFormId=${credentialFormId}`,
      { body: credential },
    ).pipe(map((response) => response.data));
  }

  update(credential: Credential): Observable<void> {
    return put<ApiResponse<void>>(
      `${domainService.apiRoot}/${credentialConstants.CREDENTIALS}/${credential.id}`,
      { body: credential },
    ).pipe(
      map((response) => response.data),
      tap(() => this.fetch(sessionQuery.getValue().credentials)),
    );
  }

  incrementRedirectTimestamp(credential: Credential) {
    return put<ApiResponse<void>>(
      `${domainService.apiRoot}/${credentialConstants.CREDENTIALS}/${credential.id}/${credentialConstants.INCREMENT_REDIRECT_TIMESTAMP}`,
      { body: {} },
    ).pipe(map((response) => response.data));
  }

  deleteCredential(credential: Credential): Observable<void> {
    return _delete<ApiResponse<void>>(
      `${domainService.apiRoot}/${credentialConstants.CREDENTIALS}/${credential.id}`,
    ).pipe(map((response) => response.data));
  }
}

export const credentialService = new CredentialService();
