import { FunctionComponent, ReactElement, useCallback, useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useObservable } from "react-use";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import Card from "@mui/material/Card";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { profile$ } from "state/session/query";
import { routes } from "./routes";
import { urlService } from "services/Url";

import AppFooter from "modules/app/Organisms/AppFooter/AppFooter";
import AppMobileNavigation from "modules/app/Organisms/AppMobileNavigation";
import DashboardAvatar from "modules/dashboard/Atoms/DashboardAvatar";
import LoadEntitiesProvider from "modules/_common/organisms/LoadEntitiesProvider";

import * as routingConstants from "lib/routing";

const useStyles = makeStyles((theme) => ({
  navigation: {
    display: "none",

    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
  },

  root: {
    minHeight: "100vh",
    background: "#f8f8f8",
    paddingBottom: 90,

    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(1),
      paddingBottom: 90,
    },

    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2),
      paddingBottom: 90,
    },
  },

  logo: {
    position: "absolute",
    top: 15,
    left: 15,

    "& img": {
      maxWidth: 125,
    },
  },

  page: {
    margin: "15px auto 0px auto",

    [theme.breakpoints.up("lg")]: {
      marginTop: 75,
    },
  },

  fabContainer: {
    position: "fixed",
    bottom: 15,
    right: 15,
    opacity: 0.3,
    transition: ".3s",

    "&:hover": {
      opacity: 1,
    },
  },

  profilePanel: {
    position: "absolute",
    display: "flex",
    flexWrap: "nowrap",
    top: 10,
    right: 10,
    marginLeft: 15,
    padding: "5px 10px",
    border: "1px solid #eeeeee",
    boxShadow: "none",
  },

  profileName: {
    fontSize: ".85rem",
  },

  profileEmail: {
    fontSize: ".7rem",
    opacity: 0.5,
  },

  support: {
    position: "absolute",
    bottom: 15,
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const AppPage: FunctionComponent<{
  children?: ReactElement;
}> = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const profile = useObservable(profile$);

  const navigateToDashboard = useCallback(() => {
    history.push(routingConstants.ROUTE_DASHBOARD_PREFIX);
  }, []);

  useEffect(() => {
    urlService.urlRoot = routingConstants.ROUTE_APP_PREFIX;
  }, []);

  return (
    <LoadEntitiesProvider>
      <>
        <div className={classes.root}>
          <div className={classes.navigation}>
            <AppMobileNavigation />
          </div>
          <div>
            <div className={classes.logo}>
              <a href="https://www.wordparrot.com">
                <img src="/img/logo.png" />
              </a>
            </div>
          </div>
          <div>
            {profile && (
              <Card className={classes.profilePanel}>
                <DashboardAvatar id={profile.id} />
                <div>
                  <Typography className={classes.profileName}>
                    {profile.name}
                  </Typography>
                  <Typography className={classes.profileEmail}>
                    {profile.email}
                  </Typography>
                </div>
              </Card>
            )}
          </div>
          <Grid container>
            <Grid item xs={12} md={10} xl={8} className={classes.page}>
              {children ? (
                <>{children}</>
              ) : (
                <Switch location={location}>
                  {routes.map((route) => (
                    <Route key={route.path} path={route.path}>
                      {route.elem}
                    </Route>
                  ))}
                </Switch>
              )}
            </Grid>
          </Grid>
          <div className={classes.fabContainer}>
            <Tooltip title="Dashboard View">
              <Fab
                onClick={navigateToDashboard}
                size="medium"
                color="primary"
                aria-label="dashboard"
              >
                <DashboardIcon></DashboardIcon>
              </Fab>
            </Tooltip>
          </div>
        </div>
        <AppFooter />
      </>
    </LoadEntitiesProvider>
  );
};

export default AppPage;
