import { Observable, of } from "rxjs";
import { PaginationResponse, PaginatorPlugin } from "@datorama/akita";
import { Webhook } from "wordparrot-types";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { isArray } from "lodash-es";
import { withTransaction } from "@datorama/akita";

import {
  ApiResponse,
  PAGINATION_COUNT_ONLY_PARAM,
  PAGINATION_PAGE_PARAM,
  PAGINATION_PER_PAGE_PARAM,
  _delete,
  get,
  post,
  put,
} from "lib/api";
import { Device, Profile, User } from "state/user/interface";
import { WebhookState } from "state/session/interface";
import { breadcrumbService } from "services/Breadcrumb";
import { domainService } from "services/Domain";

import { webhookStore } from "state/webhook/store";

import * as apiConstants from "constants/api";
import * as webhookConstants from "constants/webhooks";

export class WebhookService {
  fetch(config: WebhookState): Observable<PaginationResponse<Webhook>> {
    let url = `${domainService.apiRoot}/${webhookConstants.WEBHOOKS}`;

    url += `?${PAGINATION_PAGE_PARAM}=${config.currentPage}&${PAGINATION_PER_PAGE_PARAM}=${config.perPage}`;

    if (config.countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    if (config.pipelineId) {
      url += `&pipelineId=${config.pipelineId}`;
    }

    if (config.pipelineGroupId) {
      url += `&pipelineGroupId=${config.pipelineGroupId}`;
    }

    return get<PaginationResponse<Webhook>>(url).pipe(
      tap((response) => {
        webhookStore.upsertMany(response.data);
        breadcrumbService.set(response.data, "webhook");
      }),
    );
  }

  fetchOne(payload: { webhookId: string }): Observable<Webhook> {
    return get<ApiResponse<Webhook>>(
      `${domainService.apiRoot}/${webhookConstants.WEBHOOKS}/${payload.webhookId}`,
    ).pipe(
      map((response) => response.data),
      tap((webhook) => {
        webhookStore.upsertMany([webhook]);
        breadcrumbService.set([webhook], "webhook");
      }),
    );
  }

  ping(): Observable<{
    message: string;
  }> {
    return get<
      ApiResponse<{
        message: string;
      }>
    >(`${domainService.apiRoot}/${webhookConstants.WEBHOOKS}/ping`).pipe(
      map((response) => {
        const { message } = response.data;
        if (message !== "sandbox_ready") {
          throw new Error("Webhook service: sandbox ping failed");
        }
        return response.data;
      }),
    );
  }

  create(body: Partial<Webhook>): Observable<{ id: string }> {
    return post<ApiResponse<{ id: string }>>(
      `${domainService.apiRoot}/${webhookConstants.WEBHOOKS}`,
      {
        body,
      },
    ).pipe(map((response) => response.data));
  }

  update(body: Webhook): Observable<void> {
    return put<ApiResponse<void>>(
      `${domainService.apiRoot}/${webhookConstants.WEBHOOKS}/${body.id}`,
      {
        body,
      },
    ).pipe(map((response) => response.data));
  }

  delete(id: string): Observable<void> {
    return _delete<ApiResponse<void>>(
      `${domainService.apiRoot}/${webhookConstants.WEBHOOKS}/${id}`,
    ).pipe(map((response) => response.data));
  }

  checkAvailability(body: {
    webhookId?: string;
    route: string;
    namespace: string;
  }): Observable<ApiResponse<any>> {
    return post<ApiResponse<any>>(
      `${domainService.apiRoot}/${webhookConstants.WEBHOOKS}/${webhookConstants.CHECK_AVAILABILITY}`,
      {
        body,
      },
    );
  }

  addPipeline(body: {
    webhookId: string;
    pipelineId: string;
    add: boolean;
  }): Observable<void> {
    const { webhookId } = body;

    const url = `${domainService.apiRoot}/${webhookConstants.WEBHOOKS}/${webhookId}/${webhookConstants.ADD_PIPELINE}`;

    return post<ApiResponse<void>>(url, {
      body,
    }).pipe(map((response) => response.data));
  }
}

export const webhookService = new WebhookService();
