import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { Listener } from "wordparrot-types";

export const LISTENER_STORE = "listener_store";
export const KEY = "id";

export type ListenerState = EntityState<Listener>;

@StoreConfig({
  idKey: KEY,
  name: LISTENER_STORE,
  resettable: true,
})
export class ListenerStore extends EntityStore<ListenerState> {}

export const listenerStore = new ListenerStore();
