import {
  FunctionComponent,
  ReactElement,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Route, Switch } from "react-router-dom";
import { firstValueFrom } from "rxjs";
import { map, tap } from "rxjs/operators";
import { useLocation } from "react-router-dom";
import { useObservable } from "react-use";
import { useQuery } from "react-query";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import { chartService } from "state/chart/service";
import { credentialService } from "state/credential/service";
import { csvReportService } from "state/csv-report/service";
import { csvService } from "state/csv/service";
import { dataStoreService } from "state/data-store/service";
import { deviceService } from "services/Capacitor";
import { imageService } from "state/image/service";
import { listenerService } from "state/listener/service";
import { pipelineGroupService } from "state/pipeline-group/service";
import { pipelineService } from "state/pipeline/service";
import { pluginService } from "state/plugin/service";
import { promptService } from "state/prompt/service";
import { repositoryService } from "state/repository/service";
import { sessionQuery } from "state/session/query";
import { theme$ } from "state/site/query";
import { webhookService } from "state/webhook/service";

interface LoadEntitiesProviderProps {
  children: ReactElement;
}

const LoadEntitiesProvider: FunctionComponent<LoadEntitiesProviderProps> = ({
  children,
}) => {
  const projectsQuery = useQuery(
    ["pipelineGroupService.fetch", sessionQuery.getValue().pipelines],
    () =>
      firstValueFrom(
        pipelineGroupService.fetch(sessionQuery.getValue().pipelineGroups),
      ),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const pipelinesQuery = useQuery(
    ["pipelineService.fetch", sessionQuery.getValue().pipelines],
    () =>
      firstValueFrom(
        pipelineService.fetch({
          ...sessionQuery.getValue().pipelines,
          includeNodes: true,
        }),
      ),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const fetchRepositories$ = useMemo(
    () => repositoryService.fetch(sessionQuery.getValue().repositories),
    [],
  );
  const __ = useObservable(fetchRepositories$);

  // Fetch Credentials
  const fetchCredentials$ = useMemo(
    () => credentialService.fetch(sessionQuery.getValue().credentials),
    [],
  );
  const ___ = useObservable(fetchCredentials$);

  // const selfGroups = useObservable(selfFeaturedGroupPaginator$)
  // const subscribedGroups = useObservable(subscribedFeaturedGroupPaginator$)

  // Fetch charts
  // const fetchCharts$ = useMemo(
  //   () => chartService.fetch(sessionQuery.getValue().charts),
  //   []
  // )
  // const charts = useObservable(fetchCharts$)

  const fetchPlugins$ = useMemo(() => {
    return pluginService.fetch();
  }, []);
  const fetchPlugins = useObservable(fetchPlugins$);

  const fetchCsv$ = useMemo(
    () =>
      csvService.fetch({
        currentPage: 1,
        perPage: 10,
        total: 0,
        lastPage: 0,
        repositoryId: "",
        countOnly: false,
      }),
    [],
  );
  const fetchCsv = useObservable(fetchCsv$);

  const fetchCsvReports$ = useMemo(
    () =>
      csvReportService.fetch({
        currentPage: 1,
        perPage: 10,
        total: 0,
        lastPage: 0,
        repositoryId: "",
        countOnly: false,
      }),
    [],
  );
  const fetchCsvReports = useObservable(fetchCsvReports$);

  const fetchDataStores$ = useMemo(
    () =>
      dataStoreService.fetch({
        currentPage: 1,
        perPage: 10,
        total: 0,
        lastPage: 0,
        repositoryId: "",
        countOnly: false,
      }),
    [],
  );
  const fetchDataStores = useObservable(fetchDataStores$);

  const fetchListeners$ = useMemo(
    () =>
      listenerService.fetch({
        currentPage: 1,
        perPage: 1000,
        total: 0,
        lastPage: 0,
        countOnly: false,
        pipelineId: "",
        pipelineGroupId: "",
      }),
    [],
  );
  const fetchListeners = useObservable(fetchListeners$);

  const fetchWebhooks$ = useMemo(
    () =>
      webhookService.fetch({
        currentPage: 1,
        perPage: 1000,
        total: 0,
        lastPage: 0,
        countOnly: false,
        pipelineId: "",
        pipelineGroupId: "",
      }),
    [],
  );
  const fetchWebhooks = useObservable(fetchWebhooks$);

  const fetchPrompts$ = useMemo(
    () =>
      promptService.fetch({
        currentPage: 1,
        perPage: 1000,
        total: 0,
        lastPage: 0,
        countOnly: false,
        pipelineGroupId: "",
      }),
    [],
  );
  const fetchPrompts = useObservable(fetchPrompts$);

  useQuery(["imageService.fetch"], () =>
    firstValueFrom(
      imageService.fetch({
        currentPage: 1,
        perPage: 1000,
        total: 0,
        lastPage: 0,
        countOnly: false,
        repositoryId: "",
      }),
    ),
  );

  return <>{children}</>;
};

export default LoadEntitiesProvider;
