export const PLUGINS = "plugins";
export const PLUGINS_ZIP = "plugins-zip";
export const CONTENT = "content";
export const FETCH = "fetch";
export const PUBLIC = "public";
export const BY_PROVIDER = "by-provider";

export const FORM_JSON = "form.json";
export const PLUGIN_JSON = "plugin.json";
export const INDEX_JS = "index.js";
