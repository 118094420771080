import { useHistory, useLocation } from "react-router-dom";
import React, { useCallback, useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";

import * as routingConstants from "lib/routing";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    bottom: 15,
    zIndex: 1000,
    width: "100%",
    backgroundColor: "#eeeeee",
    borderTop: "1px solid #dddddd",
  },

  offset: {
    width: "100%",
    height: 70,
  },

  bottomOffset: {
    position: "fixed",
    bottom: 0,
    zIndex: 1000,
    width: "100%",
    height: 15,
    backgroundColor: "#eeeeee",
  },
});

export default function DashboardMobileNavigation() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const handleToPage = useCallback(
    (route: string) => () => {
      history.push(route);
    },
    [history],
  );

  const value = useMemo(() => {
    if (location.pathname === routingConstants.ROUTE_DASHBOARD_MAIN_MOBILE) {
      return 0;
    } else if (
      location.pathname === routingConstants.ROUTE_DASHBOARD_SITE_SUBSCRIPTIONS
    ) {
      return 1;
    } else if (location.pathname === routingConstants.ROUTE_DASHBOARD_PROFILE) {
      return 2;
    }
    return -1;
  }, [location.pathname]);

  return (
    <>
      <div className={classes.offset}></div>
      <BottomNavigation value={value} showLabels className={classes.root}>
        <BottomNavigationAction
          label="Menu"
          icon={<HomeIcon />}
          onClick={handleToPage(routingConstants.ROUTE_DASHBOARD_MAIN_MOBILE)}
        />
        <BottomNavigationAction
          label="Subscribed"
          icon={<SubscriptionsIcon />}
          onClick={handleToPage(
            routingConstants.ROUTE_DASHBOARD_SITE_SUBSCRIPTIONS,
          )}
        />
        <BottomNavigationAction
          label="Settings"
          icon={<SettingsIcon />}
          onClick={handleToPage(routingConstants.ROUTE_DASHBOARD_PROFILE)}
        />
      </BottomNavigation>
      <div className={classes.bottomOffset}></div>
    </>
  );
}
