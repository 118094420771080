import { DataStore } from "wordparrot-types";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export const DATA_STORE_STATE_STORE = "data_backup_store";
export const KEY = "id";

export type DataStoreState = EntityState<DataStore>;

@StoreConfig({
  idKey: KEY,
  name: DATA_STORE_STATE_STORE,
  resettable: true,
})
export class DataStoreStore extends EntityStore<DataStoreState> {}

export const dataStoreStore = new DataStoreStore();
