import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { Prompt } from "wordparrot-types";

export const PROMPT_STATE_STORE = "prompt_store";
export const KEY = "id";

export type PromptState = EntityState<Prompt>;

@StoreConfig({
  idKey: KEY,
  name: PROMPT_STATE_STORE,
  resettable: true,
})
export class PromptStore extends EntityStore<PromptState> {}

export const promptStore = new PromptStore();
