import {
  compareAsc,
  differenceInDays,
  format,
  fromUnixTime,
  getUnixTime,
  isFuture,
  isSameDay,
  isSameWeek,
  isSameYear,
} from "date-fns";
import { enUS } from "date-fns/esm/locale";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import isNumber from "lodash-es/isNumber";
import isUndefined from "lodash-es/isUndefined";
import orderBy from "lodash-es/orderBy";
import parseISO from "date-fns/parseISO";

const currentLang = "en";

export class TimeService {
  cleverTime(iso: any, includeSeconds = false): string {
    try {
      const time = new Date(iso);
      const now = new Date();

      if (isSameDay(time, now)) {
        if (includeSeconds) {
          return format(time, "h:mm:ss a");
        }
        return format(time, "h:mm a");
      }

      if (isSameYear(time, now)) {
        if (includeSeconds) {
          return format(time, "h:mm:ss a");
        }
        return format(time, "h:mm a, MMM do");
      }

      if (includeSeconds) {
        return format(time, "h:mm:ss a");
      }

      return format(time, "h:mm a, MMM do yyyy");
    } catch (e) {
      console.log(e);
      return "-";
    }
  }

  fromUnixTime(timestampInSeconds: number) {
    return fromUnixTime(timestampInSeconds);
  }

  getTime(iso: string) {
    return format(new Date(iso), "h:mm a");
  }

  date(iso: string) {
    return format(new Date(iso), "MMMM d");
  }

  day(iso: string) {
    return format(new Date(iso), "d");
  }

  dayOfWeek(iso: string) {
    return format(new Date(iso), "EEEE");
  }

  month(iso: string) {
    return format(new Date(iso), "MMMM");
  }

  year(iso: string) {
    return format(new Date(iso), "yyyy");
  }

  datePlusDifferentYear(iso: string) {
    if (isSameYear(new Date(iso), new Date())) {
      return this.date(iso);
    }

    return this.datePlusYear(iso);
  }

  datePlusYear(iso: string) {
    return format(new Date(iso), "MMMM d, yyyy");
  }

  daysSinceEpoch(iso: string): number {
    return differenceInDays(new Date(0), new Date(iso));
  }

  duration(config: { seconds: number; endpoint: number }): string {
    const { seconds, endpoint } = config;
    let duration = "";

    try {
      const difference = Math.abs(seconds - endpoint);
      const greaterThanOneHour = difference > 3600000;
      const remainder: number = difference % 3600000;
      const remainderGreaterThanOneMinute = remainder > 60000;

      // If time is more than X hours, display as hours rounded to the nearest integer. Otherwise, display units in minutes.
      if (greaterThanOneHour) {
        duration += formatDistanceStrict(new Date(0), new Date(difference), {
          locale: enUS,
          unit: "hour",
          roundingMethod: "floor",
        });
        if (remainderGreaterThanOneMinute) {
          duration +=
            " " +
            formatDistanceStrict(new Date(0), new Date(remainder), {
              locale: enUS,
              unit: "minute",
              roundingMethod: "floor",
            });
        }
      } else if (isNumber(difference)) {
        duration += formatDistanceStrict(new Date(0), new Date(difference), {
          locale: enUS,
          unit: "minute",
          roundingMethod: "floor",
        });
      }
    } catch (e) {
      console.log(e);
    }

    return duration;
  }

  isFuture(iso: string) {
    return isFuture(new Date(iso));
  }

  /**
   * Converts a specific property within an collection into a timestamp, and uses that property value on each item in the collection in order to create a sorted array. By default 'asc' will return the array sorted as oldest timestamp first.
   *
   * @param {any[]} collection
   * @param {string} prop
   * @returns {*}
   * @memberof MomentService
   */
  sorted<T>(
    collection: T[],
    prop: string,
    order: boolean | "asc" | "desc" = "asc",
  ): T[] {
    const newCollection: {
      timestamp: number;
      collectionItem: T;
    }[] = [];

    if (!collection.length) {
      return [];
    }

    for (let i = 0; i < collection.length; i++) {
      if (isUndefined(collection[i][prop])) {
        throw new Error(
          `TimeService.sorted: The ${prop} property value of a collection item is undefined.`,
        );
      }

      const timestamp: number = getUnixTime(new Date(collection[i][prop]));

      newCollection.push({
        timestamp,
        collectionItem: collection[i],
      });
    }

    const sortedCollection: any[] = orderBy(
      newCollection,
      ["timestamp"],
      [order],
    );

    return sortedCollection.map((item) => {
      return item.collectionItem;
    });
  }

  newest<T>(collection: T[], prop: string): T {
    const newest = this.sorted(collection, prop, "asc")[0];
    return newest;
  }
}

export const timeService = new TimeService();
