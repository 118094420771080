import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { Webhook } from "wordparrot-types";

export const WEBHOOK_STORE = "webhook_store";
export const KEY = "id";

export type WebhookState = EntityState<Webhook>;

@StoreConfig({
  idKey: KEY,
  name: WEBHOOK_STORE,
  resettable: true,
})
export class WebhookStore extends EntityStore<WebhookState> {}

export const webhookStore = new WebhookStore();
