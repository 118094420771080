export const SITE = "site";
export const SITES = "sites";
export const FRONT_END_ROOT = "sites";
export const SITE_SETTINGS = "settings";

export const FETCH = "fetch";
export const SUBSCRIPTIONS = "subscriptions";
export const HIGHLIGHT = "highlight";
export const SEARCH = "search";
export const SUBSCRIBE = "subscribe";
export const UNSUBSCRIBE = "unsubscribe";

export const ORDER = "order";

export const SITEid = "site-id";
export const SITE_AUTH_SCHEME = "site-auth-scheme";
export const AUTH_SSL = "auth-ssl";

export const DNS = "dns";
export const ZONES = "zones";

export const SITE_AVAILABILITY = "availability";

export const SITE_VERIFY = "verify";
export const SITE_SEARCH = "site-search";

export const SITE_WITH_DNS = "with-dns";

export const SITE_PROPERTIES_COOKIE_NAME = "site-properties";

export const SITE_DEFAULTid = "default";

export const SITE_AUTH = "site-auth";
export const SITE_SEO = "seo";
export const SITE_THEME = "theme";
export const SITE_SOCIAL = "social";
export const SITE_ANALYTICS = "analytics";
