import * as yup from "yup";
import { useEpic } from "use-epic";
import { useHistory, useLocation } from "react-router-dom";
import { useObservable } from "react-use";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import { Form, Formik } from "formik";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import GoogleButton from "react-google-button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
  appleEpic,
  appleLoginService,
  deviceService,
} from "services/Capacitor";
import { forgotPasswordEpic, googleEpic, resetPasswordEpic } from "./epic";
import { site$ } from "state/site/query";
import { snackbarService } from "services/Snackbar";

import FormikTextField from "modules/_common/forms/Formik/TextField";
import TabPanel from "modules/_common/atoms/TabPanel";

import { AppConfig } from "config";
import { useLoginMutation } from "api/login";

import SaveLoginInfo from "modules/login/Atoms/SaveLoginInfo";

import * as loginConstants from "constants/login";
import * as routingConstants from "lib/routing";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    opacity: 0.9,
    transition: ".4s opacity",

    "&:hover": {
      opacity: 1,
    },
  },

  card: {
    position: "relative",
    zIndex: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "100%",
    minHeight: 350,

    [theme.breakpoints.down("lg")]: {
      padding: "30px 0px",
    },

    [theme.breakpoints.up("lg")]: {
      minWidth: 350,
      maxWidth: 800,
      margin: "auto",
      padding: 30,
    },
  },

  cardContent: {
    width: "100%",
  },

  logoContainer: {
    position: "absolute",
    top: -45,
    left: "50%",
    zIndex: 10,
    display: "inline-block",
    transform: "translate(-50%, 0%)",
  },

  logo: {
    width: "90px",
    height: "90px",
    padding: 15,
    background: "#ffffff",
    border: "1px solid #eeeeee",
    borderRadius: "100%",
  },

  formGroup: {
    padding: "15px 0px",
  },

  submit: {
    marginTop: 15,
    textAlign: "center",
  },

  link: {
    textDecoration: "none",
    cursor: "pointer",

    "&:hover": {
      textDecoration: "underline",
    },
  },

  links: {
    marginTop: 10,
    fontWeight: 600,
    textAlign: "center",
  },

  alert: {
    margin: "20px 0px",
  },

  socialLogin: {
    padding: "30px 0px",
    textAlign: "center",
  },

  socialLoginInner: {
    padding: "15px 0px",
    textAlign: "center",
  },

  appleButton: {
    width: 240,
    paddingTop: 9,
    paddingBottom: 9,
    color: "#000000",
    fontSize: 16,
    textTransform: "none",
    background: "#ffffff",
    border: "1px solid #000000",
    borderRadius: 0,

    "&:hover": {
      color: "#000000",
      background: "#ffffff",
    },

    "& img": {
      width: 25,
      marginRight: 10,
    },
  },

  googleButton: {
    margin: "auto",
  },

  or: {
    marginBottom: 10,
  },

  marginAuto: {
    margin: "auto",
  },

  filled: {
    "& input": {
      padding: "15px 15px 10px 15px",
    },
  },
}));

const loginInitial = (
  queryEmail: string | null,
  queryPassword: string | null,
) => ({
  email: queryEmail && queryPassword ? queryEmail : "",
  password: queryEmail && queryPassword ? queryPassword : "",
});
const loginSchema = yup.object().shape({
  email: yup.string().required("Required"),
  password: yup.string().required("Required"),
});
const forgotInitial = {
  email: "",
};
const forgotSchema = yup.object().shape({
  email: yup.string().required("Required"),
});
const resetInitial = {
  key: "",
  password: "",
  confirm: "",
};
const resetSchema = yup.object().shape({
  key: yup.string().required(),
  password: yup.string().required("Required"),
  confirm: yup.string().required("Required"),
});

const renderStatus = (status: string | null, classes) => {
  switch (status) {
    case loginConstants.STATUS_LOCKED:
      return (
        <div className={classes.alert}>
          <Alert severity="warning">
            <AlertTitle>Warning: Site Locked</AlertTitle>
            This site has been locked by Wordparrot.
            <br></br>
            Please get in touch with us to learn more.
          </Alert>
        </div>
      );
    case loginConstants.STATUS_BANNED:
      return (
        <div className={classes.alert}>
          <Alert severity="error">
            <AlertTitle>Warning: Site Banned</AlertTitle>
            This site has been banned for violation of Wordparrot Terms of
            Service (TOS).
            <br></br>
            Please contact us if you believe there is an error.
          </Alert>
        </div>
      );
    case loginConstants.STATUS_POST_REGISTER:
      return (
        <div className={classes.alert}>
          <Alert severity="info">
            <AlertTitle>New Site: Verify Email</AlertTitle>A verification
            message has been sent to your email.
            <br></br>
            Once completed, you can log into your site dashboard.
          </Alert>
        </div>
      );
    default:
      return <></>;
  }
};

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const queryEmail = new URLSearchParams(useLocation().search).get("email");
  const queryPassword = new URLSearchParams(useLocation().search).get("pw");
  const [index, setIndex] = useState<number>(0);
  const [email, setEmail] = useState<string>("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const site = useObservable(site$);
  const status = useMemo(() => {
    return new URLSearchParams(document.location.search.substring(1)).get(
      "status",
    );
  }, []);

  useEffect(() => {
    // Trigger rerender on toggle
  }, [showPassword]);

  const [__, dispatchForgot] = useEpic(forgotPasswordEpic);
  const [___, dispatchReset] = useEpic(resetPasswordEpic);
  const [____, dispatchApple] = useEpic(appleEpic);
  const [_____, dispatchGoogle] = useEpic(googleEpic);

  const handleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword, setShowPassword]);

  const handleChange = useCallback(
    (number) => () => {
      setIndex(number);
    },
    [setIndex],
  );

  const handleGoogleLogin = useCallback(() => {
    dispatchGoogle({ history, location });
  }, [dispatchGoogle, history, location]);

  const handleAppleLogin = useCallback(() => {
    dispatchApple({ history, location });
  }, [dispatchApple, history, location]);

  const urlParams = new URLSearchParams(location.search);
  const loginMutator = useLoginMutation(
    {
      onError: () => {
        snackbarService.next({
          message: "You were unable to login with that name and password.",
          show: true,
          type: "error",
        });
      },
    },
    {
      saveInfo: true,
      redirectParam: urlParams.get(routingConstants.REDIRECT),
      location,
    },
  );

  const submitHandler: any = useCallback(
    (values: { email: string; password: string }) => {
      void loginMutator.mutateAsync(values);
    },
    [loginMutator],
  );

  const submitForgotHandler: any = useCallback(
    (values, actions) => {
      dispatchForgot({
        values,
        setIndex,
      });
      setEmail(values.email);
    },
    [dispatchForgot],
  );
  const submitResetHandler: any = useCallback(
    (values, actions) => {
      dispatchReset({
        values: { ...values, email },
        setIndex,
      });
    },
    [dispatchReset, email],
  );

  return (
    <div className={classes.root}>
      <div className={classes.logoContainer}>
        <img src={"/img/parrot_og.png"} className={classes.logo} />
      </div>
      <div className={classes.card}>
        <div className={classes.cardContent}>
          <br></br>
          <Typography color="primary" variant="h6" align="center" gutterBottom>
            {site?.title}
          </Typography>
          {renderStatus(status, classes)}
          <TabPanel name={""} value={0} index={index}>
            <Formik
              initialValues={loginInitial(queryEmail, queryPassword)}
              validationSchema={loginSchema}
              onSubmit={submitHandler}
            >
              {({ values, setFieldValue, errors }: any) => (
                <Form>
                  <Grid container>
                    <Grid xs={12} md={9} xl={6} className={classes.marginAuto}>
                      <FormGroup className={classes.formGroup}>
                        <FormikTextField
                          fullWidth
                          className={classes.filled}
                          variant="filled"
                          size="small"
                          name="email"
                          label=""
                          type="text"
                          helperText="Required"
                          disableFastField
                          InputProps={{
                            startAdornment: <PersonIcon />,
                          }}
                        />
                      </FormGroup>
                      <FormGroup className={classes.formGroup}>
                        <FormikTextField
                          fullWidth
                          className={classes.filled}
                          variant="filled"
                          name="password"
                          label=""
                          type={showPassword ? "text" : "password"}
                          helperText="Required"
                          disableFastField
                          InputProps={{
                            startAdornment: <LockIcon />,
                            endAdornment: (
                              <IconButton
                                size="small"
                                onClick={handleShowPassword}
                              >
                                {!showPassword && <VisibilityIcon />}
                                {showPassword && <VisibilityOffIcon />}
                              </IconButton>
                            ),
                          }}
                        />
                      </FormGroup>
                      <FormGroup className={classes.submit}>
                        <Button variant="contained" type="submit">
                          Login
                        </Button>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  {(window.location.hostname === AppConfig.signupHost ||
                    deviceService.isMobile) && (
                    <div className={classes.socialLogin}>
                      <Divider></Divider>
                      <div className={classes.socialLoginInner}>
                        <div className={classes.or}>- or -</div>
                        <div>
                          <GoogleButton
                            onClick={handleGoogleLogin}
                            className={classes.googleButton}
                          />
                        </div>
                        {appleLoginService.enabled && (
                          <>
                            <br></br>
                            <div>
                              <Button
                                className={classes.appleButton}
                                onClick={handleAppleLogin}
                              >
                                <img
                                  src="/img/logos/apple_white.png"
                                  alt="Sign in with Apple"
                                />
                                Sign in with Apple
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                      <Divider></Divider>
                    </div>
                  )}
                  <Grid container className={clsx(classes.links)}>
                    <Grid item xs={12}>
                      <br></br>
                      <Link
                        href="https://www.wordparrot.com"
                        className={clsx(classes.link, classes.or)}
                      >
                        About Wordparrot Automation
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <br></br>
                      <Link
                        className={clsx(classes.link, classes.or)}
                        onClick={handleChange(1)}
                      >
                        Forgot my password
                      </Link>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </TabPanel>
          <TabPanel name={""} value={1} index={index}>
            <Formik
              initialValues={forgotInitial}
              validationSchema={forgotSchema}
              onSubmit={submitForgotHandler}
            >
              {({ values, setFieldValue, errors }: any) => (
                <Form>
                  <FormGroup>
                    <FormikTextField
                      fullWidth
                      className={classes.filled}
                      variant="filled"
                      name="email"
                      label="Email"
                      type="text"
                      helperText="Required"
                    />
                  </FormGroup>
                  <br></br>
                  <FormGroup className={classes.submit}>
                    <Button variant="contained" type="submit">
                      Reset Password
                    </Button>
                  </FormGroup>
                  <FormGroup className={classes.submit}>
                    <Link className={classes.link} onClick={handleChange(0)}>
                      Back
                    </Link>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </TabPanel>
          <TabPanel name={""} value={2} index={index}>
            <Formik
              initialValues={resetInitial}
              validationSchema={resetSchema}
              onSubmit={submitResetHandler}
            >
              {({ values, setFieldValue, errors }: any) => (
                <Form>
                  <FormGroup>
                    <FormikTextField
                      fullWidth
                      className={classes.filled}
                      variant="filled"
                      name="key"
                      label="Reset Key"
                      type="text"
                      helperText="Required"
                    />
                  </FormGroup>
                  <br></br>
                  <FormGroup>
                    <FormikTextField
                      fullWidth
                      className={classes.filled}
                      variant="filled"
                      name="password"
                      label="Password"
                      type="password"
                      helperText="Required"
                      disableFastField
                      InputProps={{
                        endAdornment: (
                          <IconButton size="small" onClick={handleShowPassword}>
                            {!showPassword && <VisibilityIcon />}
                            {showPassword && <VisibilityOffIcon />}
                          </IconButton>
                        ),
                      }}
                    />
                  </FormGroup>
                  <br></br>
                  <FormGroup>
                    <FormikTextField
                      fullWidth
                      className={classes.filled}
                      variant="filled"
                      name="confirm"
                      label="Confirm"
                      type="password"
                      helperText="Required"
                      disableFastField
                      InputProps={{
                        endAdornment: (
                          <IconButton size="small" onClick={handleShowPassword}>
                            {!showPassword && <VisibilityIcon />}
                            {showPassword && <VisibilityOffIcon />}
                          </IconButton>
                        ),
                      }}
                    />
                  </FormGroup>
                  <br></br>
                  <FormGroup className={classes.submit}>
                    <Button variant="contained" type="submit">
                      Create New Password
                    </Button>
                  </FormGroup>
                  <FormGroup className={classes.submit}>
                    <Link className={classes.link} onClick={handleChange(0)}>
                      Back
                    </Link>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default Login;
