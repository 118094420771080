import { Observable } from "rxjs";
import { PaginationResponse } from "@datorama/akita";
import { map, tap } from "rxjs/operators";

import {
  ApiResponse,
  PAGINATION_COUNT_ONLY_PARAM,
  PAGINATION_PAGE_PARAM,
  PAGINATION_PER_PAGE_PARAM,
  _delete,
  get,
  post,
  postMultipart,
  put,
} from "lib/api";
import { imageStore } from "./store";

import { Image } from "./interface";
import { ImageState } from "state/session/interface";
import { breadcrumbService } from "services/Breadcrumb";
import { domainService } from "services/Domain";
import { sessionService } from "state/session/service";

import * as featuredGroupConstants from "constants/featured-groups";
import * as imageConstants from "constants/images";

export class ImageService {
  fetch(imageState: ImageState): Observable<PaginationResponse<Image>> {
    const { currentPage, perPage, countOnly } = imageState;

    let url = `${domainService.apiRoot}/${imageConstants.IMAGES}`;

    url += `?${PAGINATION_PAGE_PARAM}=${currentPage}&${PAGINATION_PER_PAGE_PARAM}=${perPage}`;

    if (imageState.repositoryId) {
      url += `&repositoryId=${encodeURIComponent(imageState.repositoryId)}`;
    }

    if (Array.isArray(imageState.ids)) {
      url += `&imageIds=${encodeURIComponent(imageState.ids.join(","))}`;
    }

    if (countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    return get<PaginationResponse<Image>>(url).pipe(
      tap((response) => {
        this.upsert(response.data);
        sessionService.updateImages({
          total: response.total,
          lastPage: response.lastPage,
        });
        breadcrumbService.set(response.data, "image");
      }),
    );
  }

  post(config: {
    url: string;
    height: number;
    width: number;
    format: string;
    size: number;
    publicId: string;
    title: string;
    subtitle: string;
    owner?: string;
  }): Observable<Image> {
    return post<ApiResponse<Image>>(
      `${domainService.apiRoot}/${imageConstants.IMAGES}`,
      {
        body: config,
      },
    ).pipe(map((response) => response.data));
  }

  postToFeaturedGroup(
    config: {
      url: string;
      height: number;
      width: number;
      format: string;
      size: number;
      publicId: string;
      title: string;
      subtitle: string;
    },
    featuredGroupId,
  ): Observable<void> {
    return post<ApiResponse<void>>(
      `${domainService.apiRoot}/${imageConstants.IMAGES}/${featuredGroupConstants.FEATURED_GROUPS}/${featuredGroupId}`,
      {
        body: config,
      },
    ).pipe(map((response) => response.data));
  }

  uploadMultipart(formData: FormData): Observable<{
    url: string;
    height: number;
    width: number;
    format: string;
    size: number;
    publicId: string;
  }> {
    return postMultipart<
      ApiResponse<{
        url: string;
        height: number;
        width: number;
        format: string;
        size: number;
        publicId: string;
      }>
    >(
      `${domainService.apiRoot}/${imageConstants.IMAGES}/${imageConstants.MULTIPART}`,
      {
        body: formData,
      },
    ).pipe(map((response) => response.data));
  }

  upsert(images: Image[]): void {
    imageStore.upsertMany(images);
  }

  updateStore(image: Image): void {
    imageStore.upsert(image.id, image);
    imageStore.setActive(image.id);
  }

  delete(id: string): Observable<{ id: string }> {
    return _delete<ApiResponse<{ id: string }>>(
      `${domainService.apiRoot}/${imageConstants.IMAGES}/${id}`,
    ).pipe(map((response) => response.data));
  }
}

export const imageService = new ImageService();
