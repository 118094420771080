import { Observable, firstValueFrom } from "rxjs";
import { PaginationResponse, PaginatorPlugin } from "@datorama/akita";
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
} from "rxjs/operators";
import { isNumber, random, replace, round, trim, trimStart } from "lodash-es";
import concat from "lodash-es/concat";
import find from "lodash-es/find";
import partition from "lodash-es/partition";
import uniqBy from "lodash-es/uniqBy";

import {
  ApiResponse,
  PAGINATION_COUNT_ONLY_PARAM,
  PAGINATION_PAGE_PARAM,
  PAGINATION_PER_PAGE_PARAM,
  _delete,
  get,
  post,
  put,
} from "lib/api";
import { Listener, ListenerJob } from "state/listener/interface";
import { ListenerJobState, ListenerState } from "state/session/interface";
import { breadcrumbService } from "services/Breadcrumb";
import { domainService } from "services/Domain";
import { listenerStore } from "./store";
import { sessionService } from "state/session/service";
import { timeService } from "services/Time";

import * as apiConstants from "constants/api";
import * as listenerConstants from "constants/listeners";

export class ListenerService {
  fetch(
    listenerState: ListenerState,
  ): Observable<PaginationResponse<Listener>> {
    const { currentPage, perPage, countOnly } = listenerState;

    let url = `${domainService.apiRoot}/${listenerConstants.LISTENERS}`;

    url += `?${PAGINATION_PAGE_PARAM}=${currentPage}&${PAGINATION_PER_PAGE_PARAM}=${perPage}`;

    if (countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    if (listenerState.pipelineId) {
      url += `&pipelineId=${listenerState.pipelineId}`;
    }

    if (listenerState.pipelineGroupId) {
      url += `&pipelineGroupId=${listenerState.pipelineGroupId}`;
    }

    return get<PaginationResponse<Listener>>(url).pipe(
      tap((response) => {
        listenerStore.upsertMany(response.data);
        breadcrumbService.set(response.data, "listener");
      }),
    );
  }

  fetchOne(payload: { listenerId: string }): Observable<Listener> {
    const url = `${domainService.apiRoot}/${listenerConstants.LISTENERS}/${payload.listenerId}`;

    return get<ApiResponse<Listener>>(url).pipe(
      map((response) => response.data),
      tap((listener) => {
        breadcrumbService.set([listener], "listener");
      }),
    );
  }

  addPipeline(body: {
    listenerId: string;
    pipelineId: string;
    add: boolean;
  }): Observable<void> {
    const { listenerId } = body;

    const url = `${domainService.apiRoot}/${listenerConstants.LISTENERS}/${listenerId}/${listenerConstants.ADD_PIPELINE}`;

    return post<ApiResponse<void>>(url, {
      body,
    }).pipe(map((response) => response.data));
  }

  create(values: Record<string, any>): Observable<{ id: string }> {
    const url = `${domainService.apiRoot}/${listenerConstants.LISTENERS}`;

    return post<ApiResponse<{ id: string }>>(url, {
      body: values,
    }).pipe(map((response) => response.data));
  }

  update(config: {
    listenerId: string;
    values: Record<string, any>;
  }): Observable<void> {
    const { listenerId, values } = config;

    const url = `${domainService.apiRoot}/${listenerConstants.LISTENERS}/${listenerId}`;

    return put<ApiResponse<void>>(url, {
      body: values,
    }).pipe(map((response) => response.data));
  }

  delete(config: { listenerId: string }): Observable<void> {
    const { listenerId } = config;

    const url = `${domainService.apiRoot}/${listenerConstants.LISTENERS}/${listenerId}`;

    return _delete<ApiResponse<void>>(url).pipe(
      map((response) => response.data),
    );
  }
}

export const listenerService = new ListenerService();

export class ListenerJobService {
  fetch(
    listenerJobState: ListenerJobState,
  ): Observable<PaginationResponse<ListenerJob>> {
    const { currentPage, perPage, countOnly, listenerId } = listenerJobState;

    let url = `${domainService.apiRoot}/${listenerConstants.LISTENERS}/${listenerId}/${listenerConstants.LISTENER_JOBS}`;

    url += `?${PAGINATION_PAGE_PARAM}=${currentPage}&${PAGINATION_PER_PAGE_PARAM}=${perPage}`;

    if (countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    return get<PaginationResponse<ListenerJob>>(url);
  }

  fetchOne(config: {
    listenerId: string;
    listenerJobId: string;
  }): Promise<ApiResponse<ListenerJob>> {
    const { listenerId, listenerJobId } = config;

    const url = `${domainService.apiRoot}/${listenerConstants.LISTENERS}/${listenerId}/${listenerConstants.LISTENER_JOBS}/${listenerJobId}`;

    return firstValueFrom(get<ApiResponse<ListenerJob>>(url));
  }
}

export const listenerJobService = new ListenerJobService();
