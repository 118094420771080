import { AuthorizedProfile } from "wordparrot-types";
import { Observable, combineLatest, of } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
} from "rxjs/operators";
import { createQuery } from "@datorama/akita";
import { isNil, isUndefined } from "lodash-es";

import { SessionState, sessionStore } from "./store";
import { Site } from "state/site/interface";
import { authService } from "services/Auth";
import { siteQuery } from "state/site/query";

export const sessionQuery = createQuery<SessionState>(sessionStore);

export const loading$ = sessionQuery.selectLoading();
export const error$ = sessionQuery.selectError();

export const ui$ = sessionQuery.select((state) => state.ui);

export const dashboard$ = sessionQuery.select((state) => state.dashboard);

export const responseReceived$ = ui$.pipe(
  map((ui) => {
    return ui.responseReceived;
  }),
);
export const showAlternative$ = ui$.pipe(
  map((ui) => {
    return ui.showAlternative;
  }),
);
export const highlightError$ = ui$.pipe(
  map((ui) => {
    return ui.highlightError;
  }),
);

export const mostRecentUpdate$ = ui$.pipe(
  map((ui) => {
    return ui.mostRecentUpdate;
  }),
);

export const mainPagination$ = sessionQuery
  .select((state) => state.mainPagination)
  .pipe(distinctUntilChanged());
export const hasMorePages$ = mainPagination$.pipe(
  map((mainPagination) => {
    return mainPagination.currentPage < mainPagination.lastPage;
  }),
);

export const profile$ = sessionQuery.select((state) => state.profile);

export const connectedToHub$ = profile$.pipe(
  map((profile) => !!profile.hasHubToken),
);

export const settings$ = sessionQuery.select((state) => state.settings);

export const currentEntry$ = settings$.pipe(
  map((settingsState) => settingsState.entry),
);
export const currentEntryNull$ = currentEntry$.pipe(
  map((siteEntry) => isNil(siteEntry) || isUndefined(siteEntry)),
);

export const currentEntryItem$ = settings$.pipe(
  map((settingsState) => settingsState.entryItem),
);

export const contacts$ = sessionQuery.select((state) => state.contacts);

export const auth$ = sessionQuery.select((state) => state.auth);

export const imageState$ = sessionQuery.select((state) => state.images);

export const credentialState$ = sessionQuery.select(
  (state) => state.credentials,
);

export const repositoryState$ = sessionQuery.select(
  (state) => state.repositories,
);

export const repositoryHistoryState$ = sessionQuery.select(
  (state) => state.repositoryHistory,
);

export const repositoryItemState$ = sessionQuery.select(
  (state) => state.repositoryItems,
);

export const pipelineState$ = sessionQuery.select((state) => state.pipelines);

export const pipelineGroupState$ = sessionQuery.select(
  (state) => state.pipelineGroups,
);

export const pipelineFlowState$ = sessionQuery.select(
  (state) => state.pipelineFlows,
);

export const generalFeaturedGroupState$ = sessionQuery.select(
  (state) => state.generalFeaturedGroups,
);
export const selfFeaturedGroupState$ = sessionQuery.select(
  (state) => state.selfFeaturedGroups,
);
export const subscribedFeaturedGroupState$ = sessionQuery.select(
  (state) => state.subscribedFeaturedGroups,
);

export const featuredThreadState$ = sessionQuery.select(
  (state) => state.featuredThreads,
);

export const featuredThreadReleaseState$ = sessionQuery.select(
  (state) => state.featuredThreadReleases,
);

export const featuredThreadItemState$ = sessionQuery.select(
  (state) => state.featuredThreadItems,
);

export const upstreamFeaturedThreadState$ = sessionQuery.select(
  (state) => state.upstreamFeaturedThreads,
);

export const tileState$ = sessionQuery.select((state) => state.tiles);

export const bannerState$ = sessionQuery.select((state) => state.banners);

export const trendingState$ = sessionQuery.select((state) => state.trending);

export const hubPluginState$ = sessionQuery.select((state) => state.hubPlugins);

export const chartState$ = sessionQuery.select((state) => state.charts);

export const modal$ = sessionQuery.select((state) => state.modal);

export const isLoggedIn$ = auth$.pipe(map((auth) => auth.isLoggedIn));

export const hasPermissions$ = combineLatest([
  auth$,
  siteQuery.selectActive(),
]).pipe(
  filter((values) => {
    const site = values[1];
    return !isUndefined(site);
  }),
  mergeMap((values): Observable<boolean> => {
    const authState = values[0];
    const site = values[1] as Site;

    if (authState.hasReceivedAuth) {
      return of(site.authScheme === "open" ? true : authState.isLoggedIn);
    }

    // fetchAuth may be getting called twice here
    return authService.fetchAuth().pipe(
      map((refreshProfile: AuthorizedProfile): boolean => {
        if (site.authScheme === "open") {
          return true;
        }

        return refreshProfile.acl.length > 0;
      }),
      catchError((error) => {
        return of(false);
      }),
    );
  }),
);

export const downloadHistory$ = sessionQuery
  .select((state) => state.downloadHistory)
  .pipe(distinctUntilChanged());
