import { ACL } from "wordparrot-types";
import { FunctionComponent, ReactElement, useMemo } from "react";
import { includes, isArray } from "lodash-es";
import { useObservable } from "react-use";

import { Redirect } from "react-router-dom";

import { auth$ } from "state/session/query";

import * as routingConstants from "lib/routing";

const permissionsChecker = (
  userAcl: ACL[] = [],
  required: ACL[] = [],
): boolean => {
  console.log(userAcl, required);

  if (!required.length) return true;

  return required.every((v) => userAcl.includes(v));
};

interface CheckPermissionsProps {
  permissions: ACL[];
  children: ReactElement;
  redirectTo?: string;
}

const CheckPermissions: FunctionComponent<CheckPermissionsProps> = ({
  permissions,
  redirectTo,
  children,
}) => {
  const auth = useObservable(auth$);

  const redirectQuery = useMemo(() => {
    if (redirectTo !== routingConstants.ROUTE_LOGIN) return "";
    return `?${routingConstants.REDIRECT}=${encodeURIComponent(
      window.location.href,
    )}`;
  }, [redirectTo]);

  const hasRequiredPermissions: boolean = useMemo(() => {
    if (!isArray(permissions)) {
      throw new Error(
        "CheckPermissions: permissions input must be of array type.",
      );
    }

    if (!permissions.length && !auth) {
      // Return true if permissions are empty and auth is also empty
      return true;
    }

    if (!auth) {
      return false;
    }

    if (!permissions.length || includes(auth.acl, ACL.ADMIN_SUPER)) {
      return true;
    } else {
      return permissionsChecker(auth.acl, permissions);
    }
  }, [auth, permissions]);

  return hasRequiredPermissions ? (
    <>{children}</>
  ) : (
    <>
      {redirectTo && auth && <Redirect to={`${redirectTo}${redirectQuery}`} />}
    </>
  );
};

export default CheckPermissions;
