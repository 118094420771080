import { FunctionComponent } from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  imgContainer: {
    borderRadius: "6px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "#f3f3f3",
  },
}));

interface SelectOptionImageProps {
  url: string;
  height?: number;
  width?: number;
}

const SelectOptionImage: FunctionComponent<SelectOptionImageProps> = ({
  url,
  height = 50,
  width = 50,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.imgContainer}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        backgroundImage: `url("${url}")`,
      }}
    ></div>
  );
};

export default SelectOptionImage;
