import { Csv } from "wordparrot-types";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export const CSV_STATE_STORE = "csv_store";
export const KEY = "id";

export type CsvState = EntityState<Csv>;

@StoreConfig({
  idKey: KEY,
  name: CSV_STATE_STORE,
  resettable: true,
})
export class CsvStore extends EntityStore<CsvState> {}

export const csvStore = new CsvStore();
