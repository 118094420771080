import { Theme, adaptV4Theme, createTheme } from "@mui/material/styles";

import * as themeDefaults from "theme/default";

const wordparrotTheme: Theme = createTheme(
  adaptV4Theme({
    props: {
      MuiTextField: {
        variant: "standard",
      },
      MuiSelect: {
        variant: "standard",
      },
    },
    typography: {
      fontFamily: "Noto Sans TC, sans-serif;",
      h2: {
        fontSize: "28px",
        fontWeight: 500,
      },
      body1: {
        fontSize: "16px",
      },
    },
    palette: {
      primary: {
        main: themeDefaults.primaryMain,
        light: themeDefaults.primaryLight,
        dark: themeDefaults.primaryDark,
        contrastText: themeDefaults.primaryContrastText,
      },
      secondary: {
        main: themeDefaults.secondaryMain,
        light: themeDefaults.secondaryLight,
        dark: themeDefaults.secondaryDark,
        contrastText: themeDefaults.secondaryContrastText,
      },
      info: {
        main: themeDefaults.info,
      },
      success: {
        main: themeDefaults.success,
      },
      warning: {
        main: themeDefaults.warning,
      },
      error: {
        main: themeDefaults.error,
      },
      background: {
        paper: themeDefaults.backgroundPaper,
        default: themeDefaults.backgroundDefault,
      },
      text: {
        primary: themeDefaults.textPrimary,
        secondary: themeDefaults.textSecondary,
        disabled: themeDefaults.textDisabled,
      },
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: "8px",
        },
        contained: {
          boxShadow: "0",
        },
        // containedPrimary: {
        //   backgroundColor: primary,
        //   '&:hover': {
        //     backgroundColor: active,
        //   },
        //   '&:disabled': {
        //     backgroundColor: disabled,
        //   }
        // }
      },
      // MuiInput: {
      //   root: {
      //     color: '#fff',
      //     '&$error': {
      //       color: 'green',
      //       '&:before': {
      //         borderBottomColor: 'green',
      //       },
      //       '&$focused': {
      //         color: primaryMain,
      //       },
      //     },
      //   },
      //   underline: {
      //     '&:before': {
      //       borderBottomColor: '#fff',
      //     },
      //     '&&:hover:before': {
      //       borderBottomColor: '#fff',
      //     },
      //   },
      // },
      MuiInputBase: {
        root: {},
      },
      MuiInputLabel: {
        root: {
          color: themeDefaults.textSecondary,
        },
      },
    },
  }),
);

export default wordparrotTheme;
