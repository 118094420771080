import {
  EntityState,
  EntityStore,
  EntityUIStore,
  StoreConfig,
} from "@datorama/akita";
import { FeaturedThread } from "state/featured-group/interface";
import { Pipeline, PipelineFlow } from "state/pipeline/interface";

export const PIPELINE_STATE_STORE = "pipeline_store";
export const PIPELINE_FLOW_STATE_STORE = "pipeline_flow_store";
export const UPSTREAM_PIPELINE_STATE_STORE = "upstream_pipeline_store";
export const UPSTREAM_FEATURED_THREAD_STATE_STORE =
  "upstream_featured_thread_store";

export const KEY = "id";

export type PipelineUI = {
  isOpen: boolean;
  isLoading: boolean;
};

export type PipelineState = EntityState<Pipeline>;
export type PipelineFlowState = EntityState<PipelineFlow>;
export type UpstreamPipelineState = EntityState<Pipeline>;
export type UpstreamFeaturedThreadState = EntityState<FeaturedThread>;
export type PipelineUIState = EntityState<PipelineUI>;

@StoreConfig({
  idKey: KEY,
  name: PIPELINE_STATE_STORE,
  resettable: true,
})
export class PipelineStore extends EntityStore<PipelineState> {
  ui: EntityUIStore<PipelineUIState, PipelineUI>;

  constructor() {
    super();
    this.createUIStore();
  }
}

export const pipelineStore = new PipelineStore();

@StoreConfig({
  idKey: KEY,
  name: UPSTREAM_FEATURED_THREAD_STATE_STORE,
  resettable: true,
})
export class UpstreamFeaturedThreadStore extends EntityStore<UpstreamFeaturedThreadState> {
  constructor() {
    super();
  }
}

export const upstreamFeaturedThreadStore = new UpstreamFeaturedThreadStore();

@StoreConfig({
  idKey: KEY,
  name: PIPELINE_FLOW_STATE_STORE,
  resettable: true,
})
export class PipelineFlowStore extends EntityStore<PipelineFlowState> {}

export const pipelineFlowStore = new PipelineFlowStore();
