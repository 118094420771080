import { FunctionComponent } from "react";
import { useObservable } from "react-use";
import makeStyles from "@mui/styles/makeStyles";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Image from "next/image";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { site$ } from "state/site/query";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
    backgroundColor: "#eeeeee",
  },

  mainItem: {
    margin: "auto",
    textAlign: "center",
  },

  separator: {
    padding: "0px 30px",
  },

  img: {
    width: 150,
  },
}));

const AppFooter: FunctionComponent = () => {
  const classes = useStyles();
  const site = useObservable(site$);

  return (
    <Grid container className={classes.footer}>
      <Grid item xs={12} lg={10} className={classes.mainItem}>
        <img className={classes.img} src={"/img/logo.png"} />
        <br></br>
        <br></br>
        <Typography variant="subtitle2">{site?.title}</Typography>
        <br></br>
        <Typography variant="subtitle2">
          <Link color="secondary" href="https://www.wordparrot.com">
            Home
          </Link>
          <span className={classes.separator}></span>
          <Link color="secondary" href="https://www.wordparrot.com/contact">
            Contact
          </Link>
          <span className={classes.separator}></span>
          <Link color="secondary" href="/logout">
            Logout
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AppFooter;
