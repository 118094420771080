export const IMAGES = "images";

export const MULTIPART = "multipart";

export const LOCATION_BANNERS = "banners";

export const STATUS_ACTIVE = "active";

export const TYPE_HOSTED = "hosted";
export const TYPE_EXTERNAL = "external";
