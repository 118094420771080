import {
  BlueprintSaved,
  Listener,
  Pipeline,
  PipelineGroupTag,
  PipelineGroupTagOrder,
  Project,
  Prompt,
  Webhook,
} from "wordparrot-types";
import { Observable, from } from "rxjs";
import { PaginationResponse, PaginatorPlugin } from "@datorama/akita";
import { map, mergeMap, tap } from "rxjs/operators";
import find from "lodash-es/find";

import {
  ApiResponse,
  PAGINATION_COUNT_ONLY_PARAM,
  PAGINATION_PAGE_PARAM,
  PAGINATION_PER_PAGE_PARAM,
  _delete,
  get,
  post,
  postMultipart,
  put,
} from "lib/api";

import { PipelineGroup } from "state/pipeline-group/interface";
import {
  PipelineGroupState,
  PipelineGroupStore,
  pipelineGroupStore,
} from "state/pipeline-group/store";
import { breadcrumbService } from "services/Breadcrumb";
import { domainService } from "services/Domain";
import { sessionService } from "state/session/service";
import { timeService } from "services/Time";
import { toSnakeCase } from "lib/functions";

import * as apiConstants from "constants/api";
import * as blueprintConstants from "constants/blueprints";
import * as pipelineConstants from "constants/pipelines";
import * as projectConstants from "constants/projects";
import * as routingConstants from "lib/routing";

export class PipelineGroupService {
  constructor(public readonly store: PipelineGroupStore) {}

  fetch(
    pipelineGroupState: PipelineGroupState,
  ): Observable<PaginationResponse<Project>> {
    const { currentPage, perPage, countOnly, includeAllEntities } =
      pipelineGroupState;

    let url = `${domainService.apiRoot}/${projectConstants.PROJECTS}`;

    url += `?${PAGINATION_PAGE_PARAM}=${currentPage}&${PAGINATION_PER_PAGE_PARAM}=${perPage}`;

    if (includeAllEntities) {
      url += `&includeAllEntities=true`;
    }

    if (countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    return get<PaginationResponse<Project>>(url).pipe(
      tap((response) => {
        this.store.upsertMany(response.data);
        breadcrumbService.set(response.data, "project");
      }),
    );
  }

  fetchOne(config: {
    id: string;
    includeWebhooks?: boolean;
    includeListeners?: boolean;
    includePrompts?: boolean;
    includeBlueprintInstallations?: boolean;
  }): Observable<PipelineGroup> {
    const {
      id,
      includeWebhooks,
      includeListeners,
      includePrompts,
      includeBlueprintInstallations,
    } = config;
    let url = `${domainService.apiRoot}/${projectConstants.PROJECTS}/${id}?`;

    if (includeWebhooks) {
      url += "&includeWebhooks=true";
    }
    if (includePrompts) {
      url += "&includePrompts=true";
    }
    if (includeListeners) {
      url += "&includeListeners=true";
    }
    if (includeBlueprintInstallations) {
      url += "&includeBlueprintInstallations=true";
    }

    return get<ApiResponse<PipelineGroup>>(url).pipe(
      map((response) => response.data),
      tap((project) => {
        breadcrumbService.set([project], "project");
      }),
    );
  }

  create(body: Partial<PipelineGroup>): Observable<string> {
    const url = `${domainService.apiRoot}/${projectConstants.PROJECTS}`;

    return post<ApiResponse<string>>(url, {
      body,
    }).pipe(map((response) => response.data));
  }

  update(body: PipelineGroup): Observable<void> {
    const url = `${domainService.apiRoot}/${projectConstants.PROJECTS}/${body.id}`;

    return put<ApiResponse<void>>(url, {
      body,
    }).pipe(map((response) => response.data));
  }

  duplicate(pipelineGroupId: string): Observable<{ id: string }> {
    const url = `${domainService.apiRoot}/${projectConstants.PROJECTS}/${pipelineGroupId}/${pipelineConstants.DUPLICATE}`;
    return post<ApiResponse<{ id: string }>>(url, {
      body: {},
    }).pipe(map((response) => response.data));
  }

  delete(id: string) {
    const url = `${domainService.apiRoot}/${projectConstants.PROJECTS}/${id}`;

    return _delete<ApiResponse<void>>(url).pipe(
      map((response) => response.data),
    );
  }
}

export const pipelineGroupService = new PipelineGroupService(
  pipelineGroupStore,
);

export interface EntityWrapper {
  entityType: string;
  entityId: string | null;
  index: string;
  pipeline?: Pipeline;
  prompt?: Prompt;
  listener?: Listener;
  webhook?: Webhook;
  createdAt: string;
}

export interface Tree {
  children: TreeChild[];
}

export interface TreeChild {
  id: string;
  parentEntityId: string | null;
  index: string;
  entityWrapper: EntityWrapper;
  children: TreeChild[];
}

export class PipelineGroupTagService {
  fetch(pipelineGroupId: string): Observable<ApiResponse<PipelineGroupTag[]>> {
    const url = `${domainService.apiRoot}/${pipelineConstants.PIPELINE_GROUPS}/${pipelineGroupId}/${pipelineConstants.PIPELINE_GROUP_TAGS}`;
    return get<ApiResponse<PipelineGroupTag[]>>(url).pipe();
  }

  create(body: {
    tag: {
      title: string;
      content: string;
      pipelineGroupId: string;
    };
    pipelineId?: string;
    listenerId?: string;
    promptId?: string;
    webhookId?: string;
  }): Observable<PipelineGroupTag> {
    const { pipelineGroupId } = body.tag;

    const url = `${domainService.apiRoot}/${pipelineConstants.PIPELINE_GROUPS}/${pipelineGroupId}/${pipelineConstants.PIPELINE_GROUP_TAGS}`;

    return post<ApiResponse<PipelineGroupTag>>(url, {
      body,
    }).pipe(map((response) => response.data));
  }

  delete(config: {
    pipelineGroupId: string;
    pipelineGroupTagId: string;
  }): Observable<void> {
    const { pipelineGroupId, pipelineGroupTagId } = config;
    const url = `${domainService.apiRoot}/${pipelineConstants.PIPELINE_GROUPS}/${pipelineGroupId}/${pipelineConstants.PIPELINE_GROUP_TAGS}/${pipelineGroupTagId}`;

    return _delete<ApiResponse<void>>(url).pipe(
      map((response) => response.data),
    );
  }

  buildTree(
    entityWrappers: EntityWrapper[],
    orderCollection: PipelineGroupTagOrder[],
  ): Tree {
    const topOfTree = find(orderCollection, (order) => {
      return order.index === "0";
    });

    if (!topOfTree) {
      return {
        children: [],
      };
    }

    const topEntity = find(entityWrappers, (entityWrapper) => {
      return entityWrapper.entityId === topOfTree.id;
    });

    if (!topEntity) {
      return {
        children: [],
      };
    }

    const firstChild: TreeChild = {
      id: topEntity.entityId || "",
      index: topOfTree.index,
      parentEntityId: null,
      entityWrapper: topEntity,
      children: this.buildTreeRecursive(
        topEntity.entityId || "",
        ["0"],
        entityWrappers,
        orderCollection,
        1,
      ),
    };

    return {
      children: [firstChild],
    };
  }

  private buildTreeRecursive(
    parentEntityId: string,
    currentEntityPrefix: string[],
    entityWrappers: EntityWrapper[],
    orderCollection: PipelineGroupTagOrder[],
    levelDepth: number,
  ): TreeChild[] {
    if (!entityWrappers.length) {
      return [];
    }

    const entitiesOnThisLevel = orderCollection.filter((order) => {
      const prefix = order.index.split(".");
      return prefix.length === currentEntityPrefix.length + 1 && levelDepth < 5;
    });

    if (!entitiesOnThisLevel.length) {
      return [];
    }

    const sortedEntities = entitiesOnThisLevel
      .map((order) => {
        const matchingEntity = find(
          entityWrappers || [],
          (entityWrapper) => entityWrapper.entityId === order.id,
        );

        if (matchingEntity) {
          return {
            ...matchingEntity,
            index: order.index,
          };
        }

        return null;
      })
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        try {
          const aIndex = parseInt(a.index.split(".").pop() as any);
          const bIndex = parseInt(b.index.split(".").pop() as any);
          return aIndex > bIndex ? 1 : -1;
        } catch (e) {
          return 0;
        }
      });

    const sortedIds = sortedEntities.map((entity) => entity?.entityId);

    const toReturn: TreeChild[] = [];

    const filteredWrappers = entityWrappers.filter((entity) => {
      return !sortedIds.includes(entity.entityId);
    });

    for (let i = 0; i < sortedEntities.length; i++) {
      const entityWrapper = sortedEntities[i];
      if (entityWrapper) {
        toReturn.push({
          id: entityWrapper.entityId || "",
          index: entityWrapper.index,
          parentEntityId,
          entityWrapper,
          children: this.buildTreeRecursive(
            entityWrapper.entityId || "",
            entityWrapper.index.split("."),
            filteredWrappers,
            orderCollection,
            levelDepth + 1,
          ),
        });
      }
    }

    return toReturn;
  }
}

export const pipelineGroupTagService = new PipelineGroupTagService();
