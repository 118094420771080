import {
  EntityState,
  EntityStore,
  EntityUIStore,
  StoreConfig,
} from "@datorama/akita";
import { HubPlugin, Plugin } from "state/plugin/interface";

export const PLUGIN_STATE_STORE = "plugin_store";
export const HUB_PLUGIN_STATE_STORE = "hub_plugin_store";
export const KEY = "hubPluginId";
export const HUB_KEY = "id";

export type PluginState = EntityState<Plugin>;

@StoreConfig({
  idKey: KEY,
  name: PLUGIN_STATE_STORE,
  resettable: true,
})
export class PluginStore extends EntityStore<PluginState> {}

export const pluginStore = new PluginStore();

export type HubPluginEntityState = EntityState<HubPlugin>;

@StoreConfig({
  idKey: HUB_KEY,
  name: HUB_PLUGIN_STATE_STORE,
  resettable: true,
})
export class HubPluginStore extends EntityStore<HubPluginEntityState> {}

export const hubPluginStore = new HubPluginStore();
