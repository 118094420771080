import { Category } from "./interface";
import {
  EntityState,
  EntityStore,
  StoreConfig,
  createEntityStore,
} from "@datorama/akita";

export const CATEGORY_STATE_STORE = "category_store";
export const KEY = "id";

export type CategoryState = EntityState<Category, string>;

export const categoryStore = createEntityStore<CategoryState>(
  {
    ui: false,
  },
  { idKey: KEY, name: CATEGORY_STATE_STORE, resettable: true },
);
