import { AjaxResponse, ajax } from "rxjs/ajax";
import { GetResult } from "@capacitor/storage";
import { Http, HttpResponse } from "@capacitor-community/http";
import { Observable, from, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";

import { AppConfig } from "../config";
import { deviceService, storageService } from "services/Capacitor";

export const getHeaders = (
  url: string,
  headers = {},
  contentType = "application/json",
) => {
  let modifiedHeaders: Record<string, unknown>;

  if (deviceService.isMobile) {
    modifiedHeaders = {
      ...headers,
      [AppConfig.webviewIdentifier]: deviceService.webviewIdentifier,
      "content-type": contentType,
    };
  } else {
    modifiedHeaders = {
      ...headers,
      // [AppConfig.csrfHeader]: getCookie(AppConfig.siteCsrfCookieName),
      "content-type": contentType,
    };
  }

  if (url.includes("localhost:4040") || url.includes(AppConfig.hubHost)) {
    modifiedHeaders[AppConfig.hubAccessHeader] = localStorage.getItem(
      AppConfig.hubAccessToken,
    );
  }

  return modifiedHeaders;
};

export const mobileRequest = (mobileConfig: {
  method: string;
  headers: Record<string, any>;
  data?: Record<string, any>;
  url: string;
  responseType: any;
  timeout?: number;
}): Observable<any> => {
  const { method, headers, data, url, responseType, timeout } = mobileConfig;

  if (url.search("https://") !== -1) {
    throw new Error(
      "Http error: you have already added https:// protocol and domain information to the url. Mobile requests will have protocol and domain prepended to the url automatically.",
    );
  }

  if (deviceService.isOniOS) {
    // On iOS, the JWT will be retrieved from local storage and set to the Authorization header rather than passed as an http-only cookie.
    const toFetchFromLocalStorage = [
      storageService.get({
        key: AppConfig.siteAccessCookieName,
      }),
      storageService.get({
        key: AppConfig.siteCsrfCookieName,
      }),
    ];

    if (url.includes(AppConfig.hubHost)) {
      toFetchFromLocalStorage.push(
        storageService.get({
          key: AppConfig.hubAccessToken,
        }),
      );
    }

    return from(Promise.all(toFetchFromLocalStorage)).pipe(
      catchError((e) => {
        const any: any = [];
        return of(any);
      }),
      switchMap(
        ([jwtValue, csrfValue, hubAccessToken]: [
          GetResult,
          GetResult,
          GetResult,
        ]) => {
          // Prevent repetition of duplicated domain name.
          const requestConfig = {
            method,
            headers,
            data,
            url: `${AppConfig.signupHostWithProtocol}${url}`,
            responseType,
            connectTimeout: timeout || 30000,
          };
          if (jwtValue && csrfValue) {
            requestConfig.headers = {
              ...requestConfig.headers,
              authorization: `JWT ${jwtValue.value}`,
              [AppConfig.csrfHeader]: csrfValue.value || "",
            };
          }
          if (hubAccessToken) {
            requestConfig.headers[AppConfig.hubAccessHeader] = hubAccessToken;
          }

          // Remove any nullish values to prevent runtime errors.
          for (const header in requestConfig.headers) {
            if (!requestConfig.headers[header]) {
              delete requestConfig.headers[header];
            }
          }
          return from(Http.request(requestConfig));
        },
      ),
    );
  }
  // On Android, use the http plugin to attach the csrf token to the proper header. The JWT remains in the http-only cookie.
  return from(
    Http.getCookie({
      url: AppConfig.signupHostWithProtocol,
      key: AppConfig.siteCsrfCookieName,
    }),
  ).pipe(
    switchMap((csrfValue: GetResult) => {
      const requestConfig = {
        method,
        headers,
        data,
        url: `${AppConfig.signupHostWithProtocol}${url}`,
        responseType,
        connectTimeout: timeout || 30000,
      };
      if (csrfValue) {
        requestConfig.headers = {
          ...requestConfig.headers,
          [AppConfig.csrfHeader]: csrfValue.value || "",
        };
      }
      // Remove any nullish values to prevent runtime errors.
      for (const header in requestConfig.headers) {
        if (!requestConfig.headers[header]) {
          delete requestConfig.headers[header];
        }
      }
      return from(Http.request(requestConfig));
    }),
  );
};

export function get<T>(
  url: string,
  config?: {
    body: any;
    headers: any;
    timeout?: number;
  },
): Observable<T> {
  const headers = getHeaders(url, {
    ...(config?.headers || {}),
  });

  if (deviceService.isMobile) {
    return mobileRequest({
      method: "GET",
      headers,
      url,
      responseType: "json",
      timeout: config?.timeout,
    }).pipe(
      map((httpResponse: HttpResponse): T => {
        if (httpResponse.status >= 400) {
          throw new Error(
            `Wordparrot HTTP request methods: GET request failed to '${url}'`,
          );
        }
        return httpResponse.data as T;
      }),
    );
  }

  return ajax({
    method: "GET",
    crossDomain: true,
    responseType: "json",
    url,
    headers,
    timeout: config?.timeout || 30000,
  }).pipe(
    map((ajaxResponse: AjaxResponse<unknown>): T => {
      return ajaxResponse.response as T;
    }),
  );
}

export function post<T>(
  url: string,
  config: {
    body: any;
    headers?: any;
    timeout?: number;
  },
): Observable<T> {
  const { body } = config;

  const headers = getHeaders(url, {
    ...(config.headers || {}),
  });

  if (deviceService.isMobile) {
    // All mobile requests should be explicitly written, whereas browser can use relative urls.
    return mobileRequest({
      method: "POST",
      headers,
      data: body,
      url,
      responseType: "json",
      timeout: config.timeout,
    }).pipe(
      map((httpResponse: HttpResponse): T => {
        if (httpResponse.status >= 400) {
          throw new Error(
            `Wordparrot HTTP request methods: POST request failed to '${url}'`,
          );
        }
        return httpResponse.data as T;
      }),
    );
  }

  return ajax({
    method: "POST",
    crossDomain: true,
    responseType: "json",
    url,
    headers,
    body,
    timeout: config.timeout || 30000,
  }).pipe(
    map((ajaxResponse: AjaxResponse<unknown>): T => {
      return ajaxResponse.response as T;
    }),
  );
}

export function postMultipart<T>(
  url: string,
  config: {
    body: any;
    headers?: any;
    timeout?: number;
  },
): Observable<T> {
  const { body } = config;

  const headers = getHeaders(
    url,
    {
      ...(config.headers || {}),
    },
    "multipart/form-data",
  );

  // Cannot specify content-type header.
  delete headers["content-type"];

  if (deviceService.isMobile) {
    return mobileRequest({
      method: "POST",
      headers,
      data: body,
      url,
      responseType: "json",
      timeout: config.timeout,
    }).pipe(
      map((httpResponse: HttpResponse): T => {
        if (httpResponse.status >= 400) {
          throw new Error(
            `Wordparrot HTTP request methods: POST multipart request failed to '${url}'`,
          );
        }
        return httpResponse.data as T;
      }),
    );
  }

  return ajax({
    method: "POST",
    crossDomain: true,
    responseType: "json",
    url,
    headers,
    body,
    timeout: config.timeout || 30000,
  }).pipe(
    map((ajaxResponse: AjaxResponse<unknown>): T => {
      return ajaxResponse.response as T;
    }),
  );
}

export function postRaw<T>(
  url: string,
  config: {
    body: any;
    headers?: any;
    filename?: string;
    timeout?: number;
  },
): Observable<T> {
  const { body, filename } = config;

  const headers = getHeaders(
    url,
    {
      ...(config.headers || {}),
    },
    "application/octet-stream",
  );

  if (deviceService.isMobile) {
    return mobileRequest({
      method: "POST",
      headers,
      data: body,
      url,
      responseType: "json",
      timeout: config.timeout,
    }).pipe(
      map((httpResponse: HttpResponse): T => {
        if (httpResponse.status >= 400) {
          throw new Error(
            `Wordparrot HTTP request methods: POST raw request failed to '${url}'`,
          );
        }
        return httpResponse.data as T;
      }),
    );
  }

  return ajax({
    method: "POST",
    crossDomain: true,
    responseType: "json",
    url,
    headers,
    body,
    timeout: config.timeout || 30000,
  }).pipe(
    map((ajaxResponse: AjaxResponse<unknown>): T => {
      return ajaxResponse.response as T;
    }),
  );
}

export function put<T>(
  url: string,
  config: {
    body: any;
    headers?: any;
    timeout?: number;
  },
): Observable<T> {
  const { body } = config;

  const headers = getHeaders(url, {
    ...(config.headers || {}),
  });

  if (deviceService.isMobile) {
    return mobileRequest({
      method: "PUT",
      headers,
      data: body,
      url,
      responseType: "json",
      timeout: config.timeout,
    }).pipe(
      map((httpResponse: HttpResponse): T => {
        if (httpResponse.status >= 400) {
          throw new Error(
            `Wordparrot HTTP request methods: PUT request failed to '${url}'`,
          );
        }
        return httpResponse.data as T;
      }),
    );
  }

  return ajax({
    method: "PUT",
    crossDomain: true,
    responseType: "json",
    url,
    headers,
    body,
    timeout: config.timeout || 30000,
  }).pipe(
    map((ajaxResponse: AjaxResponse<unknown>): T => {
      return ajaxResponse.response as T;
    }),
  );
}

export function _delete<T>(
  url: string,
  config?: {
    headers: any;
    timeout?: number;
  },
): Observable<T> {
  const headers = getHeaders(url, {
    ...(config?.headers || {}),
  });

  if (deviceService.isMobile) {
    return mobileRequest({
      method: "DELETE",
      headers,
      url,
      responseType: "json",
      timeout: config?.timeout,
    }).pipe(
      map((httpResponse: HttpResponse): T => {
        if (httpResponse.status >= 400) {
          throw new Error(
            `Wordparrot HTTP request methods: DELETE request failed to '${url}'`,
          );
        }
        return httpResponse.data as T;
      }),
    );
  }

  return ajax({
    method: "DELETE",
    crossDomain: true,
    responseType: "json",
    url,
    headers,
    timeout: config?.timeout || 30000,
  }).pipe(
    map((ajaxResponse: AjaxResponse<unknown>): T => {
      return ajaxResponse.response as T;
    }),
  );
}

export interface ApiResponse<T> {
  result: boolean;
  data: T;
  message: string;
}

export const PAGINATION_PAGE_PARAM = "currentPage";
export const PAGINATION_PER_PAGE_PARAM = "perPage";
export const PAGINATION_COUNT_ONLY_PARAM = "countOnly";
export const PAGINATION_SEARCH_PARAM = "search";
