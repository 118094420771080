export const PROMPTS = "prompts";
export const INSTANCES = "instances";
export const IDENTIFIER = "identifier";
export const PROMPT_RECIPIENTS_API = "prompt-recipients";
export const RECIPIENTS = "recipients";
export const PROMPTS_DISPLAY = "prompts-display";
export const MARK_COMPLETED = "completed";
export const DOWNSTREAM = "downstream";

export const PROMPT_STATUS_OPEN = "open";
export const PROMPT_STATUS_CLOSED = "closed";
export const PROMPT_STATUS_COMPLETED = "completed";
export const PROMPT_STATUS_ERROR = "error";
export const PROMPT_STATUS_HIDDEN = "hidden";

export const DEFAULT_FILE = "default_file";
export const INSTANCE_FILE = "instance_file";
export const FILES = "files";

export const PROMPT_RECIPIENT_STATUS_SUBMIT = "submit";
export const PROMPT_RECIPIENT_STATUS_REJECT = "reject";
export const PROMPT_RECIPIENT_STATUS_PENDING = "pending";
