import { Credential } from "./interface";
import {
  EntityState,
  EntityStore,
  StoreConfig,
  createEntityStore,
} from "@datorama/akita";

export const CREDENTIAL_STORE = "credential_store";
export const KEY = "id";

export type CredentialState = EntityState<Credential>;

@StoreConfig({ idKey: KEY, name: CREDENTIAL_STORE, resettable: true })
export class CredentialStore extends EntityStore<CredentialState> {}

export const credentialStore = new CredentialStore();
