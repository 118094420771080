export const STORAGE = "storage";
export const FOLDERS = "folders";

// Actions
export const DUPLICATE_CREDENTIAL = "duplicate";
export const CHECK_USED_SPACE = "check-used-space";
export const TEMP_FOLDER = "temp-folder";
export const UPLOAD = "upload";
export const DIRECT_DOWNLOAD = "direct-download";
export const VERIFY = "verify";
export const WORKING_ONLY = "working_only";
export const WITH_FORM = "with-form";
export const INCREMENT_REDIRECT_TIMESTAMP = "increment-redirect-timestamp";

// Folder names
export const CREDENTIAL_FOLDER_MAIN = "_main";

// Credentials
export const CREDENTIALS = "credentials";
export const CREDENTIAL_CHECK = "check";
export const CREDENTIAL_FORMS = "credential-forms";

// Providers
export const BOX = "box";
export const DROPBOX = "dropbox";
export const AWS_S3 = "aws_s3";
export const CSV = "csv";

// Status
export const CREDENTIAL_STATUS_UNVERIFIED = "unverified";
export const CREDENTIAL_STATUS_VALID = "valid";
export const CREDENTIAL_STATUS_INVALID = "invalid";
export const CREDENTIAL_STATUS_EXPIRED = "expired";
export const CREDENTIAL_STATUS_REVOKED = "revoked";
export const CREDENTIAL_STATUS_DISABLED = "disabled";

// Types
export const TYPE_URL = "url";
export const TYPE_APP_STORAGE = "app_storage";
export const TYPE_THIRD_PARTY = "third_party";
export const TYPE_THIRD_PARTY_STORAGE = "third_party_storage";
