import { useObservable } from "react-use";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import { loading$ } from "state/session/query";

import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";

const useStyles = makeStyles({
  loadingBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
});

function LoadingState() {
  const classes = useStyles();
  const loading = useObservable(loading$);

  return (
    <Modal open={loading || false}>
      <div className={classes.loadingBlock}>
        <CircularProgress />
      </div>
    </Modal>
  );
}

export default LoadingState;
