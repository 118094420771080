import React, { lazy, useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import Skeleton from "@mui/material/Skeleton";

import DashboardShell from "modules/dashboard/Organisms/DashboardShell";
import DashboardTitle from "modules/dashboard/Atoms/DashboardTitle";

const DashboardLoading = () => {
  return (
    <DashboardShell>
      <DashboardTitle route={{ title: "Loading" }}>
        <div>
          <Skeleton animation="wave" variant="text" />
          <br></br>
          <Skeleton animation="wave" variant="text" />
          <br></br>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"100%"}
            height={60}
          />
        </div>
      </DashboardTitle>
    </DashboardShell>
  );
};

export default DashboardLoading;
