import { FunctionComponent } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflow: "hidden",
    background: "#f8f8f8",
  },

  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    minHeight: "100vh",
    margin: 0,
    padding: 10,
  },

  containerCentered: {
    alignItems: "center",
  },
}));

interface FormUIContainerProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  centered?: boolean;
  children: any;
}

const FormUIContainer: FunctionComponent<FormUIContainerProps> = ({
  xs,
  sm,
  md,
  lg,
  xl,
  centered,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        className={clsx(classes.container, {
          [classes.containerCentered]: centered,
        })}
      >
        <Grid
          item
          xs={xs || 12}
          sm={sm || 10}
          md={md || 8}
          lg={lg || 6}
          xl={xl || 6}
        >
          {children || <></>}
        </Grid>
      </Grid>
    </div>
  );
};

export default FormUIContainer;
