import {
  EntityState,
  EntityStore,
  StoreConfig,
  createEntityStore,
} from "@datorama/akita";
import { PipelineGroup } from "state/pipeline-group/interface";

export const PIPELINE_GROUP_STATE_STORE = "pipeline_group_store";
export const KEY = "id";

export type PipelineGroupState = EntityState<PipelineGroup>;

@StoreConfig({
  idKey: KEY,
  name: PIPELINE_GROUP_STATE_STORE,
  resettable: true,
})
export class PipelineGroupStore extends EntityStore<PipelineGroupState> {
  constructor() {
    super();
  }
}

export const pipelineGroupStore = new PipelineGroupStore();
