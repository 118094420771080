import { FieldMetaProps, useField } from "formik";
import { FunctionComponent, useCallback } from "react";
import isString from "lodash-es/isString";
import makeStyles from "@mui/styles/makeStyles";

import { FastField } from "formik";
import AccountCircle from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  textarea: {
    width: "100%",
    maxWidth: "100%",
    minHeight: "100px",
    maxHeight: "150px",
    marginTop: "15px",
    padding: "10px 2px",
  },

  textareaError: {
    border: `2px solid ${theme.palette.error.main}`,
  },
}));

const renderHelperText = (errorStatus, input) => {
  if (!errorStatus) {
    return null;
  }
  if (isString(input)) {
    return <FormHelperText>Required field</FormHelperText>;
  }
  return <FormHelperText component={input} />;
};

const FormikTextField = ({ name, label, helperText, ...optionalProps }) => {
  const { readonly, disableFastField, textarea, ...remainingProps } =
    optionalProps;

  const classes = useStyles();
  const [formikField, meta, helpers] = useField({
    ...remainingProps,
    name,
  });
  const {
    error,
    touched,
  }: FieldMetaProps<{ error: string; touched: boolean }> = meta;

  const errorStatus: boolean = (error && touched) === true;

  const clearField = useCallback(() => {
    void helpers.setValue("");
  }, [helpers]);

  if (textarea) {
    return (
      // <FastField name={name}>
      <TextareaAutosize
        {...formikField}
        className={
          errorStatus
            ? clsx(classes.textarea, classes.textareaError)
            : classes.textarea
        }
      />
      // </FastField>
    );
  }

  if (disableFastField) {
    return (
      <FormControl>
        <TextField
          {...formikField}
          label={label}
          error={errorStatus}
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: readonly || false,
            endAdornment: meta.value ? (
              <InputAdornment position="end">
                <IconButton size="small" onClick={clearField}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : (
              <></>
            ),
          }}
          {...remainingProps}
        />
        {renderHelperText(errorStatus, helperText)}
      </FormControl>
    );
  }

  return (
    <FormControl>
      <FastField name={name}>
        {({ field, form, meta }) => (
          <TextField
            {...field}
            label={label}
            error={errorStatus}
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: readonly || false,
              endAdornment: meta.value ? (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={clearField}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ) : (
                <></>
              ),
            }}
            {...remainingProps}
          />
        )}
      </FastField>
      {renderHelperText(errorStatus, helperText)}
    </FormControl>
  );
};

export default FormikTextField;
