import {
  EntityState,
  EntityStore,
  EntityUIStore,
  StoreConfig,
} from "@datorama/akita";
import { Repository, RepositoryHistory } from "state/repository/interface";

export const REPOSITORY_STATE_STORE = "repository_store";
export const REPOSITORY_HISTORY_STATE_STORE = "repository_history_store";
export const KEY = "id";

export type RepositoryUI = {
  isOpen: boolean;
  isLoading: boolean;
};

export type RepositoryState = EntityState<Repository>;
export type RepositoryUIState = EntityState<RepositoryUI>;

@StoreConfig({
  idKey: KEY,
  name: REPOSITORY_STATE_STORE,
  resettable: true,
})
export class RepositoryStore extends EntityStore<RepositoryState> {
  ui: EntityUIStore<RepositoryUIState, RepositoryUI>;

  constructor() {
    super();
    this.createUIStore();
  }
}

export const repositoryStore = new RepositoryStore();
