import { useMemo } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";

import { NavLink } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

import { breadcrumbService } from "services/Breadcrumb";
import { isUUID } from "lib/functions";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    ".MuiBreadcrumbs-li": {
      display: "flex",
      alignItems: "center",
    },
  },

  breadcrumbLink: {
    color: theme.palette.secondary.main,
    maxWidth: 100,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },

  span: {
    display: "inline-block",
    height: "100%",
  },

  selected: {
    fontWeight: 600,
  },
}));

const DashboardBreadcrumbs = ({ breadcrumbs }) => {
  const classes = useStyles();
  const modifiedBreadcrumbs = useMemo(() => {
    return breadcrumbs.slice(1);
  }, [breadcrumbs]);

  return (
    <Breadcrumbs className={classes.breadcrumbs}>
      {modifiedBreadcrumbs?.map(({ match, breadcrumb }, index) => {
        const segments = match.url.split("/");
        const lastSegment: string = segments[segments.length - 1];
        if (isUUID(lastSegment)) {
          if (index === modifiedBreadcrumbs?.length - 1) {
            return (
              <span
                className={clsx(classes.selected, classes.span)}
                key={match.url}
              >
                <Typography className={classes.selected}>
                  {{
                    ...breadcrumb,
                    props: {
                      ...breadcrumb.props,
                      children: breadcrumbService.get(lastSegment),
                    },
                  }}
                </Typography>
              </span>
            );
          }
          return (
            <span className={classes.span} key={match.url}>
              <NavLink className={classes.breadcrumbLink} to={match.url}>
                {{
                  ...breadcrumb,
                  props: {
                    ...breadcrumb.props,
                    children: breadcrumbService.get(lastSegment),
                  },
                }}
              </NavLink>
            </span>
          );
        }

        if (index === modifiedBreadcrumbs?.length - 1) {
          return (
            <span className={classes.span} key={match.url}>
              <Typography className={classes.selected}>{breadcrumb}</Typography>
            </span>
          );
        }
        return (
          <span className={classes.span} key={match.url}>
            <NavLink className={classes.breadcrumbLink} to={match.url}>
              {breadcrumb}
            </NavLink>
          </span>
        );
      })}
    </Breadcrumbs>
  );
};

export default withBreadcrumbs([])(DashboardBreadcrumbs);
