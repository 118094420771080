import { useLocation } from "react-router-dom";
import { useObservable } from "react-use";
import React, { useMemo } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import { AppConfig } from "config";
import { getCookie } from "lib/functions";
import { isLoggedIn$ } from "state/session/query";
import { theme$ } from "state/site/query";

import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";

import AppLoading from "modules/app/Organisms/AppLoading";
import DashboardLoading from "modules/_common/organisms/DashboardLoading";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: (props: any) =>
      props?.themeToUse?.primaryMain || "#eeeeee",
  },

  logoContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: 90,
    height: 90,
    transform: "translate(-50%, -50%)",
  },

  logo: {
    animationDuration: "2s",
    animationIterationCount: "infinite",
  },

  roller: {
    "& div:after": {
      background: (props: any) => props?.themeToUse?.secondaryMain || "#232323",
    },
  },
}));

export default function Loading() {
  const location = useLocation();
  const isLoggedIn = useObservable(isLoggedIn$);
  const theme = useObservable(theme$);

  const themeToUse = useMemo(() => {
    const themeFromLocalStorage = localStorage.getItem("site-theme");
    if (themeFromLocalStorage) {
      return JSON.parse(themeFromLocalStorage);
    } else if (theme) {
      localStorage.setItem("site-theme", JSON.stringify(theme));
      return theme;
    } else {
      return null;
    }
  }, [theme]);

  const classes = useStyles({ themeToUse });

  if (isLoggedIn) {
    const locationFragments = location.pathname.split("/");
    if (locationFragments.includes("dashboard")) {
      return <DashboardLoading />;
    }
    if (locationFragments.includes("app")) {
      return <AppLoading />;
    }
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Fade in timeout={250}>
          <div className={classes.logoContainer}>
            <div className={clsx("lds-roller", classes.roller)}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </Fade>
      </Grid>
    </Grid>
  );
}
