import { Observable } from "rxjs";

import { ApiResponse, get, post } from "lib/api";
import { domainService } from "services/Domain";

import * as setupConstants from "constants/setup";

export class SetupService {
  checkIfRequired(): Observable<ApiResponse<null>> {
    const url = `${domainService.apiRoot}/${setupConstants.SETUP}/${
      setupConstants.SETUP_REQUIRED_MESSAGE
    }?time=${Date.now().toString()}`;
    return get(url);
  }

  checkIfInProgress(): Observable<ApiResponse<null>> {
    const url = `${domainService.apiRoot}/${setupConstants.SETUP}/${setupConstants.SETUP_IN_PROGRESS}`;
    return get(url);
  }

  createDb(body: {
    host: string
    siteName: string
    name: string
    language: string
    email: string
    password: string
    confirm: string
  }): Observable<ApiResponse<null>> {
    const url = `${domainService.apiRoot}/${setupConstants.SETUP}`;
    return post(url, { body });
  }
}

export const setupService = new SetupService();
