import * as yup from "yup";
import { useCallback, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";

import { useSetupCreateDb, useSetupRequired } from "api/setup";

import { Form, Formik } from "formik";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { snackbarService } from "services/Snackbar";

import FormikSelect from "modules/_common/forms/Formik/Select";
import FormikTextField from "modules/_common/forms/Formik/TextField";
import UnauthenticatedContainer from "modules/_common/pages/FormUIContainer/FormUIContainer";


const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    opacity: 0.9,
    transition: ".4s opacity",
    marginTop: 120,

    "&:hover": {
      opacity: 1,
    },
  },

  card: {
    position: "relative",
    zIndex: 3,
    maxWidth: "100%",
    minHeight: 350,
    background: "#f3f3f3",

    [theme.breakpoints.down("lg")]: {
      padding: "30px 0px",
    },

    [theme.breakpoints.up("lg")]: {
      minWidth: 350,
      maxWidth: 800,
      margin: "auto",
      padding: 30,
    },
  },

  cardContent: {
    width: "100%",
  },

  logoContainer: {
    position: "absolute",
    top: -45,
    left: "50%",
    zIndex: 10,
    display: "inline-block",
    transform: "translate(-50%, 0%)",
  },

  logo: {
    width: "90px",
    height: "90px",
    padding: 15,
    background: "#ffffff",
    border: "1.5px solid grey",
    borderRadius: "100%",
  },

  submit: {
    marginTop: 30,
    textAlign: "center",
  },

  link: {
    textDecoration: "none",
    cursor: "pointer",

    "&:hover": {
      textDecoration: "underline",
    },
  },

  links: {
    marginTop: 10,
    fontWeight: 600,
    textAlign: "center",
  },

  alert: {
    margin: "20px 0px",
  },

  socialLogin: {
    padding: "30px 0px",
    textAlign: "center",
  },

  socialLoginInner: {
    padding: "15px 0px",
    textAlign: "center",
  },

  appleButton: {
    width: 240,
    paddingTop: 9,
    paddingBottom: 9,
    color: "#000000",
    fontSize: 16,
    textTransform: "none",
    background: "#ffffff",
    border: "1px solid #000000",
    borderRadius: 0,

    "&:hover": {
      color: "#000000",
      background: "#ffffff",
    },

    "& img": {
      width: 25,
      marginRight: 10,
    },
  },

  googleButton: {
    margin: "auto",
  },

  or: {
    marginBottom: 10,
  },

  marginAuto: {
    margin: "auto",
  },

  filled: {
    "& input": {
      padding: "15px 15px 10px 15px",
    },
  },

  formGroupLabel: {
    marginTop: 30,
    fontWeight: 600,
    fontStyle: "italic",
    color: theme.palette.primary.main,
  },
}));

const loginSchema = yup.object().shape({
  host: yup.string().required("Required"),
  siteName: yup.string().required("Required"),
  name: yup.string().required("Required"),
  language: yup.string().required("Required"),
  email: yup.string().required("Required"),
  password: yup.string().required("Required").min(10),
  confirm: yup.string().required("Required").min(10),
});

type SetupState = 'loading' | "start" | 'ready' | 'alreadyCreated' | 'error'

const Setup = () => {
  const classes = useStyles();
  const [state, setState] = useState<SetupState>('loading')

  useSetupRequired(null, {
    onSuccess: (data) => {
      if (data.result) {
        setState('start');
      } else {
        setState('alreadyCreated');
      }
    },
    onError: () => {
      setState('error');
    }
  });

  const setupDb = useSetupCreateDb({
    onSuccess: () => {
      snackbarService.next({
        show: true,
        type: 'success',
        message: 'Success!'
      })
      setState('ready');
    },
    onError: () => {
      snackbarService.next({
        show: true,
        type: 'error',
        message: 'Error: database setup failed.'
      })
      console.log('DB setup error')
    },
  });

  const onSubmit = useCallback((values: {
    host: string
    siteName: string
    name: string
    language: string
    email: string
    password: string
    confirm: string
  }) => {
    snackbarService.next({
      show: true,
      type: 'loading',
      message: 'Now attempting to set up database...'
    })
    void setupDb.mutateAsync(values)
  }, [setupDb])

  if (state === "loading") {
    return (
      <div className="App">
        <UnauthenticatedContainer>
          <div className={classes.root}>
            <div className={classes.logoContainer}>
              <img src={"/img/parrot_og.png"} className={classes.logo} />
            </div>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ minHeight: "200px" }}
                >
                  <CircularProgress />
                </Box>
              </CardContent>
            </Card>
          </div>
        </UnauthenticatedContainer>
      </div>
    );
  }


  if (state === "error") {
    return (
      <div className="App">
        <UnauthenticatedContainer>
          <div className={classes.root}>
            <div className={classes.logoContainer}>
              <img src={"/img/parrot_og.png"} className={classes.logo} />
            </div>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <br></br>
                <Typography variant="h2" align="center">
                  Setup Error
                </Typography>
                <br></br>
                <Typography gutterBottom variant="subtitle1">
                  Could not connect with Wordparrot API Server.
                </Typography>
                <Typography gutterBottom variant="subtitle1" color="primary">
                  Please check with your IT administrator for details.
                </Typography>
              </CardContent>
            </Card>
          </div>
        </UnauthenticatedContainer>
      </div>
    );
  }

  if (state === "ready") {
    return (
      <div className="App">
        <UnauthenticatedContainer>
          <div className={classes.root}>
            <div className={classes.logoContainer}>
              <img src={"/img/parrot_og.png"} className={classes.logo} />
            </div>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <br></br>
                <Typography variant="h2" align="center">
                  Setup Getting Ready!
                </Typography>
                <br></br>
                <Typography gutterBottom variant="subtitle1">
                  Your MySQL database, site, and user information are being
                  loaded.
                </Typography>
                <Typography gutterBottom variant="subtitle1" color="primary">
                  Please check back in a few minutes.
                </Typography>
                <br></br>
                <CircularProgress />
              </CardContent>
            </Card>
          </div>
        </UnauthenticatedContainer>
      </div>
    );
  }

  if (state === "alreadyCreated") {
    return (
      <div className="App">
        <UnauthenticatedContainer>
          <div className={classes.root}>
            <div className={classes.logoContainer}>
              <img src={"/img/parrot_og.png"} className={classes.logo} />
            </div>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <br></br>
                <Typography variant="h2" align="center">
                  Setup Finished.
                </Typography>
                <br></br>
                <Typography gutterBottom variant="subtitle1">
                  Your MySQL database, site, and user information have been
                  loaded already.
                </Typography>
                <br></br>
                <Link href="/">
                  <Typography gutterBottom variant="subtitle1" color="primary">
                    Back to Homepage
                  </Typography>
                </Link>
              </CardContent>
            </Card>
          </div>
        </UnauthenticatedContainer>
      </div>
    );
  }

  return (
    <div className="App">
      <UnauthenticatedContainer>
        <div className={classes.root}>
          <div className={classes.logoContainer}>
            <img src={"/img/parrot_og.png"} className={classes.logo} alt="logo" />
          </div>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h6">Welcome to Wordparrot!</Typography>
              <Typography gutterBottom>
                Create a database with your login credentials.
              </Typography>
              <Formik
                initialValues={{
                  host: location.hostname,
                  language: "en",
                  siteName: "",
                  name: "",
                  email: "",
                  password: "",
                  confirm: "",
                }}
                validationSchema={loginSchema}
                onSubmit={onSubmit}
              >
                {() => (
                  <Form>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography className={classes.formGroupLabel}>
                          {'First, let\'s figure out some site details.'}
                        </Typography>
                        <FormGroup>
                          <FormikTextField
                            fullWidth
                            name="siteName"
                            label="What to name this website?"
                            helperText=""
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormikTextField
                            fullWidth
                            name="host"
                            label="Hostname (Leave blank unless you know what you're doing)"
                            helperText="Must set hostname!"
                          />
                        </FormGroup>
                        <Typography className={classes.formGroupLabel}>
                          Next, add some information about yourself.
                        </Typography>
                        <FormGroup>
                          <FormikTextField
                            fullWidth
                            name="name"
                            label="Enter Your Real Name"
                            helperText=""
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormikSelect
                            fullWidth
                            name="language"
                            label="Language"
                            helperText="Must set language!"
                            options={[
                              {
                                value: "en",
                                label: "English",
                              },
                            ]}
                          />
                        </FormGroup>
                        <Typography className={classes.formGroupLabel}>
                          Finally, set your user login information.
                        </Typography>
                        <FormGroup>
                          <FormikTextField
                            fullWidth
                            name="email"
                            label="Email"
                            helperText=""
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormikTextField
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            helperText=""
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormikTextField
                            fullWidth
                            type="password"
                            name="confirm"
                            label="Confirm Password"
                            helperText=""
                          />
                        </FormGroup>
                        <FormGroup className={classes.submit}>
                          <Button variant="contained" type="submit">
                            Create Database
                          </Button>
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </div>
      </UnauthenticatedContainer>
    </div>
  );
};

export default Setup;
