import { ReactElement, lazy } from "react";

import { Redirect } from "react-router";

import CheckPermissions from "modules/_common/atoms/CheckPermissions";

import * as routingConstants from "lib/routing";

const DashboardMain = lazy(
  () => import("modules/dashboard/Pages/DashboardMain"),
);
const DashboardMainMobileMenu = lazy(
  () => import("modules/dashboard/Pages/DashboardMainMobileMenu"),
);
const DashboardThemeDisplay = lazy(
  () => import("modules/dashboard/Pages/DashboardThemeDisplay"),
);
const Storage = lazy(() => import("modules/credential/Pages/_parent"));
const DashboardSettings = lazy(
  () => import("modules/dashboard/Pages/DashboardSettings"),
);
const DashboardCategories = lazy(
  () => import("modules/dashboard/Pages/DashboardCategories"),
);
const DashboardProfile = lazy(
  () => import("modules/dashboard/Pages/DashboardProfile"),
);
const DashboardTileMain = lazy(
  () => import("modules/dashboard/Pages/DashboardTileMain"),
);
const DashboardTileList = lazy(
  () => import("modules/dashboard/Pages/DashboardTileList"),
);
const DashboardDomains = lazy(
  () => import("modules/dashboard/Pages/DashboardDomains"),
);
const DashboardCategoryMeta = lazy(
  () => import("modules/dashboard/Pages/DashboardCategoryMeta"),
);
const Plugin = lazy(() => import("modules/plugin/Pages/Plugin"));

const Pipeline = lazy(() => import("modules/pipeline/Pages/Pipeline"));
const Blueprint = lazy(() => import("modules/blueprint/Pages/Blueprint"));
const Prompt = lazy(() => import("modules/prompt/Pages/Prompt"));
const ProjectModuleRoot = lazy(
  () => import("modules/project/Pages/_ProjectModuleRoot"),
);
const Repository = lazy(() => import("modules/repository/Pages/Repository"));
const User = lazy(() => import("modules/user/Pages/User"));
const Smtp = lazy(() => import("modules/smtp/Pages/Smtp"));
const Media = lazy(() => import("modules/media/Pages/Media"));
const DynamicForm = lazy(() => import("modules/dynamic-form/parent"));

export const routes: {
  elem: ReactElement;
  path: string;
  title?: string;
  subtitle?: string;
  wrapperOnly?: boolean;
  onMobile?: ReactElement;
}[] = [
  {
    path: "loading",
    elem: <></>,
    title: "",
    subtitle: "",
  },
  {
    path: routingConstants.ROUTE_DASHBOARD_MAIN_MOBILE,
    elem: <DashboardMainMobileMenu />,
    title: "Welcome",
    subtitle: "Click below to get started.",
  },
  {
    path: routingConstants.ROUTE_DASHBOARD_MAIN,
    elem: <DashboardMain />,
    title: "My Dashboard",
    subtitle: "Add tiles to customize what you see after login.",
  },
  {
    path: routingConstants.ROUTE_DASHBOARD_TILE_LIST,
    elem: <DashboardTileList />,
    title: "Manage Tiles",
    subtitle: "Add a set of mixed content to your dashboard home page.",
  },
  {
    path: routingConstants.ROUTE_DASHBOARD_TILE_CREATE,
    elem: <DashboardTileMain />,
    title: "Create Tile",
    subtitle: "Add a set of mixed content to your dashboard home page.",
  },
  {
    path: routingConstants.ROUTE_DASHBOARD_TILE_UPDATE,
    elem: <DashboardTileMain />,
    title: "Update Tile",
    subtitle: "",
  },
  {
    path: routingConstants.ROUTE_DASHBOARD_SETTINGS,
    elem: (
      <CheckPermissions permissions={[]}>
        <DashboardSettings />
      </CheckPermissions>
    ),
    title: "Site Settings",
    subtitle: "Change important site configuration.",
  },
  {
    path: routingConstants.ROUTE_DASHBOARD_CATEGORIES_META_UPDATE,
    elem: (
      <CheckPermissions permissions={[]}>
        <DashboardCategoryMeta />
      </CheckPermissions>
    ),
    title: "Update Meta Tags",
    subtitle: "Customize SEO for your category.",
  },
  {
    path: routingConstants.ROUTE_DASHBOARD_CATEGORIES_META,
    elem: (
      <CheckPermissions permissions={[]}>
        <DashboardCategoryMeta />
      </CheckPermissions>
    ),
    title: "Category Meta Tags",
    subtitle: "Customize SEO for your category.",
  },
  {
    path: routingConstants.ROUTE_DASHBOARD_CATEGORIES,
    elem: (
      <CheckPermissions permissions={[]}>
        <DashboardCategories />
      </CheckPermissions>
    ),
    title: "Category Configuration",
    subtitle: "Drag and drop into your desired order.",
  },
  {
    path: routingConstants.ROUTE_DASHBOARD_THEME,
    elem: (
      <CheckPermissions permissions={[]}>
        <DashboardThemeDisplay />
      </CheckPermissions>
    ),
    title: "Theme Configuration",
    subtitle:
      "Customize your color scheme with primary, secondary and special message colors.",
  },
  {
    path: routingConstants.ROUTE_DASHBOARD_PROFILE,
    elem: <DashboardProfile />,
    title: "Profile Settings",
    subtitle: "Update your publicly available information here.",
  },
  {
    path: routingConstants.ROUTE_DASHBOARD_DOMAINS,
    elem: (
      <CheckPermissions permissions={[]}>
        <DashboardDomains />
      </CheckPermissions>
    ),
    title: "Dashboard Settings",
    subtitle: "Find information about the platform here.",
  },
  {
    path: routingConstants.ROUTE_CREDENTIALS_PREFIX,
    elem: (
      <CheckPermissions permissions={[]}>
        <Storage />
      </CheckPermissions>
    ),
    wrapperOnly: true,
  },
  {
    path: routingConstants.ROUTE_PIPELINES_PREFIX,
    elem: (
      <CheckPermissions permissions={[]}>
        <Pipeline />
      </CheckPermissions>
    ),
    wrapperOnly: true,
  },
  {
    path: routingConstants.ROUTE_BLUEPRINT_PREFIX,
    elem: (
      <CheckPermissions permissions={[]}>
        <Blueprint />
      </CheckPermissions>
    ),
    wrapperOnly: true,
  },
  {
    path: routingConstants.ROUTE_PROJECTS_PREFIX,
    elem: (
      <CheckPermissions permissions={[]}>
        <ProjectModuleRoot />
      </CheckPermissions>
    ),
    wrapperOnly: true,
  },
  {
    path: routingConstants.ROUTE_PROMPTS_PREFIX,
    elem: (
      <CheckPermissions permissions={[]}>
        <Prompt />
      </CheckPermissions>
    ),
    wrapperOnly: true,
  },
  {
    path: routingConstants.ROUTE_REPOSITORY_PREFIX,
    elem: (
      <CheckPermissions permissions={[]}>
        <Repository />
      </CheckPermissions>
    ),
    wrapperOnly: true,
  },
  {
    path: routingConstants.ROUTE_USER_PREFIX,
    elem: (
      <CheckPermissions permissions={[]}>
        <User />
      </CheckPermissions>
    ),
    wrapperOnly: true,
  },
  {
    path: routingConstants.ROUTE_MEDIA_PREFIX,
    elem: (
      <CheckPermissions permissions={[]}>
        <Media />
      </CheckPermissions>
    ),
    wrapperOnly: true,
  },
  {
    path: routingConstants.ROUTE_PLUGINS_PREFIX,
    elem: (
      <CheckPermissions permissions={[]}>
        <Plugin />
      </CheckPermissions>
    ),
    wrapperOnly: true,
  },
  {
    path: routingConstants.ROUTE_SMTP_PREFIX,
    elem: (
      <CheckPermissions permissions={[]}>
        <Smtp />
      </CheckPermissions>
    ),
    wrapperOnly: true,
  },
  {
    path: routingConstants.ROUTE_DYNAMIC_FORMS_PREFIX,
    elem: (
      <CheckPermissions permissions={[]}>
        <DynamicForm />
      </CheckPermissions>
    ),
    wrapperOnly: true,
  },
  {
    path: routingConstants.ROUTE_DASHBOARD_PREFIX,
    elem: <Redirect to={routingConstants.ROUTE_DASHBOARD_MAIN} />,
  },
];
