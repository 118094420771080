import { useHistory, useLocation } from "react-router-dom";
import { useObservable } from "react-use";
import React, { useCallback, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
// import SearchBar from 'material-ui-search-bar'
import { Site } from "wordparrot-types";
import Head from "next/head";
import clsx from "clsx";
import isUndefined from "lodash-es/isUndefined";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";

import { NavLink } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Link from "@mui/material/Link";
import SettingsIcon from "@mui/icons-material/Settings";
import Typography from "@mui/material/Typography";

import { desktopOffset } from "modules/dashboard/Organisms/DashboardShell";
import { deviceService } from "services/Capacitor";
import { profile$ } from "state/session/query";
import { site$ } from "state/site/query";
import sections from "modules/dashboard/Organisms/DashboardSidebar";

import DashboardAvatar from "modules/dashboard/Atoms/DashboardAvatar";
import DashboardBreadcrumbs from "modules/dashboard/Atoms/DashboardBreadcrumbs";

import * as routingConstants from "lib/routing";

const titleContainerTopPadding = 45;
const titleContainerHeight = 75;

const routesWithoutBackOption: string[] = [
  routingConstants.ROUTE_DASHBOARD_MAIN,
  routingConstants.ROUTE_FEATURED_GROUP_LIST_LATEST,
];

const useStyles = makeStyles((theme) => ({
  fixedMobileOffset: {
    width: "100%",
    height: titleContainerTopPadding + titleContainerHeight,
    background: "transparent",
  },

  desktopOffset: {
    [theme.breakpoints.up("lg")]: {
      paddingTop: desktopOffset,
    },
  },

  avatarContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  titleContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: titleContainerHeight,
    padding: "10px 21px",
    background: "#f3f3f3",

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  titleMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  titleMiddle: {
    flexGrow: 1,
    overflow: "hidden",
  },

  titleMiddleMobile: {
    textAlign: "center",
  },

  titleMiddleRightPadding: {
    paddingRight: 50,
  },

  backButtonContainer: {
    minWidth: 35,
    paddingRight: 10,
  },

  mobilePadding: {
    padding: "10px 15px",
    paddingTop: titleContainerTopPadding,
  },

  mobileFixed: {
    position: "fixed",
    zIndex: 1000,
    marginTop: -(titleContainerTopPadding + titleContainerHeight),
  },

  title: {
    overflow: "hidden",
    fontWeight: 500,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  subtitle: {
    overflow: "hidden",
    fontWeight: 600,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  paddingInner: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
  },

  search: {
    border: "1px solid #eeeeee",
    boxShadow: "none",

    "& .MuiInput-root": {
      width: 50,
      transition: ".5s width",
    },

    "& .MuiInput-root.Mui-focused": {
      width: 200,
    },
  },

  profilePanel: {
    display: "flex",
    alignItems: "center",
    marginLeft: 15,
    padding: "5px 10px",
    border: "1px solid #eeeeee",
    boxShadow: "none",
  },

  profileName: {
    fontSize: ".85rem",
  },

  profileEmail: {
    fontSize: ".7rem",
    opacity: 0.5,
  },

  settingsContainer: {
    marginRight: 0,
  },

  breadcrumbLink: {
    color: theme.palette.secondary.main,
  },
}));

interface DashboardTitleProps {
  route?: any;
  children?: any;
  addOffset?: boolean;
  isMobile?: boolean;
}

const renderTitle = (route: any, site?: Site) => {
  if (!site) {
    return "";
  }
  const siteTitle = site.title || "Wordparrot";
  const pageTitle = route?.title ? `${route?.title} | ` : "";
  return `${pageTitle}${siteTitle}`;
};

const DashboardTitle = (props: DashboardTitleProps) => {
  const { route } = props;
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [state, setState] = useState("");
  const profile = useObservable(profile$);
  const site = useObservable(site$);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      {deviceService.isMobile && props.addOffset && (
        <div className={classes.fixedMobileOffset}></div>
      )}
      <div className={classes.desktopOffset}></div>
      <div className={classes.paddingInner}>
        {renderTitle(route, site) && (
          <Head>
            <title>{renderTitle(route, site)}</title>
          </Head>
        )}
        <>{props.children}</>
      </div>
    </>
  );
};

export default DashboardTitle;
