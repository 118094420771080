import { Route, Switch } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useObservable } from "react-use";
import React, { lazy, useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Skeleton from "@mui/material/Skeleton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { deviceService } from "services/Capacitor";
import { drawerWidth, mainWidth } from "modules/dashboard/Pages/Dashboard";
import { routes } from "modules/dashboard/Pages/Dashboard/routes";

import AppPage from "modules/app/Pages/App";

const useStyles = makeStyles((theme) => ({}));

const AppLoading = () => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <AppPage>
      <div>
        <Skeleton animation="wave" variant="text" />
        <br></br>
        <Skeleton animation="wave" variant="text" />
        <br></br>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"100%"}
          height={60}
        />
      </div>
    </AppPage>
  );
};

export default AppLoading;
