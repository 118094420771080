import { DataStore, DataStoreVersion } from "wordparrot-types";
import { Observable } from "rxjs";
import { PaginationResponse, PaginatorPlugin } from "@datorama/akita";
import { map, mergeMap, switchMap, tap } from "rxjs/operators";

import {
  ApiResponse,
  PAGINATION_COUNT_ONLY_PARAM,
  PAGINATION_PAGE_PARAM,
  PAGINATION_PER_PAGE_PARAM,
  _delete,
  get,
  post,
  put,
} from "lib/api";
import { DataStoreState, DataStoreVersionState } from "state/session/interface";
import { DataStoreStore, dataStoreStore } from "./store";
import { domainService } from "services/Domain";

import * as apiConstants from "constants/api";
import * as dataStoreConstants from "constants/data-stores";

export class DataStoreService {
  constructor(public readonly store: DataStoreStore) {}

  fetch(
    DataStoreState: DataStoreState,
  ): Observable<PaginationResponse<DataStore>> {
    const { currentPage, perPage, countOnly } = DataStoreState;

    let url = `${domainService.apiRoot}/${dataStoreConstants.DATA_STORES}`;

    url += `?${PAGINATION_PAGE_PARAM}=${currentPage}&${PAGINATION_PER_PAGE_PARAM}=${perPage}`;

    if (countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    if (DataStoreState.repositoryId) {
      url += `&repositoryId=${DataStoreState.repositoryId}`;
    }

    return get<PaginationResponse<DataStore>>(url).pipe(
      tap((response) => {
        this.store.upsertMany(response.data);
      }),
    );
  }

  fetchOne(payload: { dataStoreId: string }): Observable<DataStore> {
    const url = `${domainService.apiRoot}/${dataStoreConstants.DATA_STORES}/${payload.dataStoreId}`;

    return get<ApiResponse<DataStore>>(url).pipe(
      map((response) => response.data),
      tap((data) => {
        this.store.upsert(data, {});
      }),
    );
  }

  fetchVersionsById(
    dataStoreVersionState: DataStoreVersionState,
  ): Observable<PaginationResponse<DataStoreVersion>> {
    const { currentPage, perPage, countOnly, dataStoreId } =
      dataStoreVersionState;

    let url = `${domainService.apiRoot}/${dataStoreConstants.DATA_STORES}/${dataStoreId}/${dataStoreConstants.DATA_STORE_SAVED}`;

    url += `?${PAGINATION_PAGE_PARAM}=${currentPage}&${PAGINATION_PER_PAGE_PARAM}=${perPage}`;

    if (countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    return get<PaginationResponse<DataStoreVersion>>(url);
  }

  create(body: Partial<DataStore>): Observable<{
    id: string;
  }> {
    const url = `${domainService.apiRoot}/${dataStoreConstants.DATA_STORES}`;

    return post<
      ApiResponse<{
        id: string;
      }>
    >(url, {
      body,
    }).pipe(
      map((apiResponse) => {
        return apiResponse.data;
      }),
    );
  }

  update(body: DataStore): Observable<ApiResponse<void>> {
    const url = `${domainService.apiRoot}/${dataStoreConstants.DATA_STORES}/${body.id}`;

    return put<ApiResponse<void>>(url, {
      body,
    });
  }

  delete(id: string): Observable<ApiResponse<void>> {
    const url = `${domainService.apiRoot}/${dataStoreConstants.DATA_STORES}/${id}`;
    return _delete<ApiResponse<void>>(url);
  }
}

export const dataStoreService = new DataStoreService(dataStoreStore);
