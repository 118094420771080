import {} from "react-router";
import {
  ActionPerformed,
  PermissionStatus,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import { AuthorizedProfile } from "wordparrot-types";
import {
  BehaviorSubject,
  Observable,
  firstValueFrom,
  from,
  merge,
  of,
} from "rxjs";
import { Device, DeviceId, DeviceInfo } from "@capacitor/device";
import { GetResult, Storage } from "@capacitor/storage";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { mergeMap, timeout } from "rxjs/operators";
import isEmpty from "lodash-es/isEmpty";

import {
  SignInWithApple,
  SignInWithAppleOptions,
  SignInWithAppleResponse,
} from "@capacitor-community/apple-sign-in";

import { ApiResponse, get, post } from "lib/api";
import { AppConfig } from "config";
import { authService, registerProfile } from "services/Auth";
import { domainService } from "services/Domain";
import { getCookie } from "lib/functions";
import { snackbarService } from "services/Snackbar";

import * as apiConstants from "constants/api";
import * as authConstants from "constants/auth";
import * as routingConstants from "lib/routing";
import * as userConstants from "constants/users";

export class DeviceService {
  info?: DeviceInfo;
  _id: DeviceId;
  webviewIdentifier: string =
    getCookie(AppConfig.webviewIdentifier) ||
    AppConfig.webviewIdentifierDefault;

  device$: Observable<any>;
  info$ = new BehaviorSubject<DeviceInfo | null>(null);
  isMobile$: Observable<boolean> = this.info$.pipe(
    filter((deviceInfo: any): boolean => {
      return !isEmpty(deviceInfo);
    }),
    map((deviceInfo: DeviceInfo) => {
      return deviceInfo.platform === "android" || deviceInfo.platform === "ios";
    }),
  );
  // deviceUUID$ = this.info$.pipe(
  //   map((deviceInfo: DeviceInfo) => {
  //     return deviceInfo.uuid
  //   })
  // )

  constructor() {
    void (async () => {
      if (typeof window === "undefined") {
        return;
      }

      const info = await Device.getInfo();
      this.info = info;
      this.info$.next(this.info);

      const id = await Device.getId();
      this.id = id;
    })();
  }

  set id(id: DeviceId) {
    this._id = id;
  }

  get id() {
    throw new Error("Cannot access DeviceId directly. Use uuid getter.");
  }

  get uuid() {
    return this._id.uuid;
  }

  get isMobile() {
    if (
      process.env.NEXT_PUBLIC_IS_MOBILE === "true" &&
      process.env.NODE_ENV === "development"
    ) {
      return true;
    }
    return this.info?.platform === "android" || this.info?.platform === "ios";
  }

  get isOniOS() {
    return this.info?.platform === "ios";
  }

  get isiOSOrWeb() {
    return this.info?.platform === "ios" || this.info?.platform === "web";
  }

  get name() {
    return this.info?.name;
  }

  get model() {
    return this.info?.model;
  }
}

export const deviceService = new DeviceService();

export class StorageService {
  set(config: { key: string; value: string }): Promise<void> {
    return Storage.set(config);
  }

  remove(config: { key: string }): Promise<void> {
    return Storage.remove(config);
  }

  get(config: { key: string }): Promise<GetResult> {
    return Storage.get(config);
  }

  clear(): Promise<void> {
    return Storage.clear();
  }
}

export const storageService = new StorageService();

export class PushNotificationService {
  register(authorizedProfile: AuthorizedProfile): void {
    void (async () => {
      try {
        const id = await Device.getId();
        const info = await Device.getInfo();
        await firstValueFrom(
          this.requestPermissions(authorizedProfile, id, info),
        );
      } catch (e) {
        alert(e);
        // Registration has failed for some reason
      }
    })();
  }

  requestPermissions(
    authorizedProfile: AuthorizedProfile,
    id: DeviceId,
    info: DeviceInfo,
  ): Observable<boolean> {
    return post<ApiResponse<void>>(
      `${domainService.apiRoot}/${userConstants.USERS}/${userConstants.DEVICES}/${userConstants.CHECK_REGISTRATION}`,
      {
        body: {
          uuid: id.uuid,
        },
      },
    ).pipe(
      switchMap((response) => {
        if (response.result) {
          // We already have device info, skip.
          return of(true);
        }
        return from(PushNotifications.requestPermissions()).pipe(
          switchMap((result: PermissionStatus) => {
            if (result.receive !== "granted") {
              // Register with Apple / Google to receive push via APNS/FCM
              throw new Error("Permissions not granted");
            }
            return of(this.registerDevice());
          }),
          switchMap(() => from(PushNotifications.register())),
          map(() => true),
        );
      }),
      tap(() => {
        this.addListeners();
      }),
      catchError((err) => {
        return of(true);
      }),
    );
  }

  registerDevice() {
    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", (token: Token) => {
      void (async () => {
        try {
          const id = await Device.getId();
          const info = await Device.getInfo();
          await firstValueFrom(this.saveDeviceInfo(id, info, token));
        } catch (e) {
          // Saving device has failed
        }
      })();
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      // Error when registering.
    });
  }

  saveDeviceInfo(
    id: DeviceId,
    info: DeviceInfo,
    token: Token,
  ): Observable<void> {
    return post<ApiResponse<void>>(
      `${domainService.apiRoot}/${userConstants.USERS}/${userConstants.DEVICES}/${userConstants.REGISTER_DEVICE}`,
      {
        body: {
          uuid: id.uuid,
          name: info.name,
          model: info.model,
          platform: info.platform,
          os: info.operatingSystem,
          registrationToken: token.value,
          pushEnabled: true,
        },
      },
    ).pipe(
      map((response) => response.data),
      catchError((err) => {
        alert("register device error");
        alert(err);
        return of();
      }),
    );
  }

  addListeners() {
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        //'Push received: ' + JSON.stringify(notification)
      },
    );

    // Method called when tapping on a notification - see AppUrlListener component
    // PushNotifications.addListener(
    //   'pushNotificationActionPerformed',
    //   (actionPerformed: ActionPerformed) => {

    //   }
    // )
  }
}

export const pushNotificationService = new PushNotificationService();

class AppleLoginService {
  get enabled() {
    return deviceService.isOniOS;
  }

  main(history) {
    if (deviceService.isOniOS) {
      return this.loginOnMobile(history);
    }
    return of({});
  }

  loginOnMobile(history) {
    const options: SignInWithAppleOptions = {
      clientId: AppConfig.clientId,
      redirectURI: `${domainService.apiRoot}/${authConstants.APPLE_AUTH}/${authConstants.REDIRECT_MOBILE}`,
      scopes: "email name",
      state: "login",
      nonce: Date.now().toString(),
    };

    return from(SignInWithApple.authorize(options)).pipe(
      switchMap((result: SignInWithAppleResponse) => {
        const {
          familyName,
          givenName,
          authorizationCode,
          identityToken,
          email,
        } = result.response;

        const body: any = {
          authorization: {
            id_token: identityToken,
            code: authorizationCode,
            state: options.state,
          },
        };

        if (familyName && email && givenName) {
          body.user = {
            name: {
              firstName: givenName,
              lastName: familyName,
            },
            email,
          };
        }

        return post<
          ApiResponse<{
            tokens: {
              refreshToken: string;
              accessToken: string;
              csrfToken: string;
            };
            authorizedProfile: AuthorizedProfile;
          }>
        >(options.redirectURI, {
          body,
        });
      }),
      map((response) => response.data),
      switchMap((data) => {
        return from(
          Promise.all([
            storageService.set({
              key: AppConfig.siteRefreshCookieName,
              value: data.tokens.accessToken,
            }),
            storageService.set({
              key: AppConfig.siteAccessCookieName,
              value: data.tokens.accessToken,
            }),
            storageService.set({
              key: AppConfig.siteCsrfCookieName,
              value: data.tokens.csrfToken,
            }),
          ]).then(() => data.authorizedProfile),
        );
      }),
      tap(registerProfile),
      tap(() => {
        history.push(routingConstants.ROUTE_DASHBOARD_MAIN_MOBILE);
      }),
      catchError((e) => {
        snackbarService.next({
          message: "You have failed to login with this name and password.",
          type: "error",
          show: true,
        });
        return of({});
      }),
    );
  }
}

export const appleLoginService = new AppleLoginService();

export function appleEpic(action$, state$, deps) {
  return action$.pipe(
    mergeMap(({ history }) => appleLoginService.main(history)),
  );
}

class GoogleLoginService {
  main(history) {
    if (deviceService.isMobile) {
      return this.loginOnMobile(history);
    } else {
      return this.loginOnWeb();
    }
  }

  loginOnWeb() {
    snackbarService.next({
      message: "Please wait a moment...",
      type: "loading",
      show: true,
    });

    return get<ApiResponse<{ authUrl: string }>>(
      `${domainService.apiRoot}/${authConstants.GOOGLE_AUTH}/${authConstants.GET_AUTH_URL}`,
    ).pipe(
      map((response) => {
        const authUrl = response.data.authUrl;
        window.location.href = authUrl;
      }),
    );
  }

  loginOnMobile(history) {
    return from(GoogleAuth.signIn()).pipe(
      switchMap((response) => {
        const { serverAuthCode } = response;

        const url = `${domainService.apiRoot}/${authConstants.GOOGLE_AUTH}/${authConstants.REDIRECT_MOBILE}?code=${serverAuthCode}`;

        return get<
          ApiResponse<{
            tokens: {
              refreshToken: string;
              accessToken: string;
              csrfToken: string;
            };
            authorizedProfile: AuthorizedProfile;
          }>
        >(url);
      }),
      map((response) => response.data),
      switchMap((data) => {
        return from(
          Promise.all([
            storageService.set({
              key: AppConfig.siteRefreshCookieName,
              value: data.tokens.accessToken,
            }),
            storageService.set({
              key: AppConfig.siteAccessCookieName,
              value: data.tokens.accessToken,
            }),
            storageService.set({
              key: AppConfig.siteCsrfCookieName,
              value: data.tokens.csrfToken,
            }),
          ]).then(() => data.authorizedProfile),
        );
      }),
      tap(registerProfile),
      tap((val) => {
        history.push(routingConstants.ROUTE_DASHBOARD_MAIN_MOBILE);
      }),
      catchError((e) => {
        snackbarService.next({
          message: "You have failed to login with this name and password.",
          type: "error",
          show: true,
        });
        return of({});
      }),
    );
  }
}

export const googleLoginService = new GoogleLoginService();
