import { CsvReport, CsvReportSaved } from "wordparrot-types";
import { Observable } from "rxjs";
import { PaginationResponse, PaginatorPlugin } from "@datorama/akita";
import { map, mergeMap, switchMap, tap } from "rxjs/operators";

import {
  ApiResponse,
  PAGINATION_COUNT_ONLY_PARAM,
  PAGINATION_PAGE_PARAM,
  PAGINATION_PER_PAGE_PARAM,
  _delete,
  get,
  post,
  postMultipart,
  put,
} from "lib/api";
import { CsvReportSavedState, CsvReportState } from "state/session/interface";
import { CsvReportStore, csvReportStore } from "./store";
import { domainService } from "services/Domain";

import * as apiConstants from "constants/api";
import * as csvReportConstants from "constants/csv-reports";

export class CsvReportService {
  constructor(public readonly store: CsvReportStore) {}

  fetch(
    csvReportState: CsvReportState,
  ): Observable<PaginationResponse<CsvReport>> {
    const { currentPage, perPage, countOnly } = csvReportState;

    let url = `${domainService.apiRoot}/${csvReportConstants.CSV_REPORTS}`;

    url += `?${PAGINATION_PAGE_PARAM}=${currentPage}&${PAGINATION_PER_PAGE_PARAM}=${perPage}`;

    if (countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    if (csvReportState.repositoryId) {
      url += `&repositoryId=${csvReportState.repositoryId}`;
    }

    return get<PaginationResponse<CsvReport>>(url).pipe(
      tap((response) => {
        this.store.upsertMany(response.data);
      }),
    );
  }

  fetchOne(payload: { csvReportId: string }): Observable<CsvReport> {
    const url = `${domainService.apiRoot}/${csvReportConstants.CSV_REPORTS}/${payload.csvReportId}`;

    return get<ApiResponse<CsvReport>>(url).pipe(
      map((response) => response.data),
      tap((data) => {
        this.store.upsert(data, {});
      }),
    );
  }

  fetchSavedById(
    csvReportSavedState: CsvReportSavedState,
  ): Observable<PaginationResponse<CsvReportSaved>> {
    const { currentPage, perPage, countOnly, csvReportId } =
      csvReportSavedState;

    let url = `${domainService.apiRoot}/${csvReportConstants.CSV_REPORTS}/${csvReportId}/${csvReportConstants.CSV_REPORT_SAVED}`;

    url += `?${PAGINATION_PAGE_PARAM}=${currentPage}&${PAGINATION_PER_PAGE_PARAM}=${perPage}`;

    if (countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    return get<PaginationResponse<CsvReportSaved>>(url);
  }

  create(body: Partial<CsvReport>): Observable<{
    id: string;
  }> {
    const url = `${domainService.apiRoot}/${csvReportConstants.CSV_REPORTS}`;

    return post<
      ApiResponse<{
        id: string;
      }>
    >(url, {
      body,
    }).pipe(
      map((apiResponse) => {
        return apiResponse.data;
      }),
    );
  }

  update(body: CsvReport): Observable<ApiResponse<void>> {
    const url = `${domainService.apiRoot}/${csvReportConstants.CSV_REPORTS}/${body.id}`;

    return put<ApiResponse<void>>(url, {
      body,
    });
  }

  delete(id: string): Observable<ApiResponse<void>> {
    const url = `${domainService.apiRoot}/${csvReportConstants.CSV_REPORTS}/${id}`;
    return _delete<ApiResponse<void>>(url);
  }
}

export const csvReportService = new CsvReportService(csvReportStore);
