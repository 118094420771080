import { Csv, CsvMetadata } from "wordparrot-types";
import { Observable } from "rxjs";
import { PaginationResponse, PaginatorPlugin } from "@datorama/akita";
import { map, mergeMap, switchMap, tap } from "rxjs/operators";
import { withTransaction } from "@datorama/akita";

import {
  ApiResponse,
  PAGINATION_COUNT_ONLY_PARAM,
  PAGINATION_PAGE_PARAM,
  PAGINATION_PER_PAGE_PARAM,
  _delete,
  get,
  post,
  postMultipart,
  put,
} from "lib/api";
import { CsvState } from "state/session/interface";
import { CsvStore, csvStore } from "./store";
import { breadcrumbService } from "services/Breadcrumb";
import { domainService } from "services/Domain";

import * as apiConstants from "constants/api";
import * as csvConstants from "constants/csv";

export class CsvService {
  constructor(public readonly store: CsvStore) {}

  fetch(csvState: CsvState): Observable<PaginationResponse<Csv>> {
    const { currentPage, perPage, countOnly } = csvState;

    let url = `${domainService.apiRoot}/${csvConstants.CSV}`;

    url += `?${PAGINATION_PAGE_PARAM}=${currentPage}&${PAGINATION_PER_PAGE_PARAM}=${perPage}`;

    if (countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    if (csvState.repositoryId) {
      url += `&repositoryId=${csvState.repositoryId}`;
    }

    return get<PaginationResponse<Csv>>(url).pipe(
      tap((response) => {
        this.store.upsertMany(response.data);
        breadcrumbService.set(response.data, "csv");
      }),
    );
  }

  fetchOne(payload: { csvId: string }): Observable<Csv> {
    const url = `${domainService.apiRoot}/${csvConstants.CSV}/${payload.csvId}`;

    return get<ApiResponse<Csv>>(url).pipe(
      map((response) => response.data),
      tap((csv) => {
        breadcrumbService.set([csv], "csv");
      }),
    );
  }

  create(csvMetadata: CsvMetadata): Observable<{
    id: string;
  }> {
    const url = `${domainService.apiRoot}/${csvConstants.CSV}`;

    return post<
      ApiResponse<{
        id: string;
      }>
    >(url, {
      body: {
        csvMetadata,
      },
    }).pipe(
      map((apiResponse) => {
        return apiResponse.data;
      }),
    );
  }

  update(csvMetadata: CsvMetadata): Observable<ApiResponse<void>> {
    const url = `${domainService.apiRoot}/${csvConstants.CSV}/${csvMetadata.id}`;

    return put<ApiResponse<void>>(url, {
      body: {
        csvMetadata,
      },
    });
  }

  delete(id: string): Observable<ApiResponse<void>> {
    const url = `${domainService.apiRoot}/${csvConstants.CSV}/${id}`;
    return _delete<ApiResponse<void>>(url);
  }

  deleteRows(csvId: string): Observable<ApiResponse<void>> {
    const url = `${domainService.apiRoot}/${csvConstants.CSV}/${csvId}/${csvConstants.ROWS}`;
    return _delete<ApiResponse<void>>(url);
  }

  parseCsv(body: FormData): Observable<
    ApiResponse<{
      filename: string;
      csvArray: string[][];
    }>
  > {
    const url = `${domainService.apiRoot}/${csvConstants.CSV}/${csvConstants.PARSE}`;

    return postMultipart<
      ApiResponse<{
        filename: string;
        csvArray: string[][];
      }>
    >(url, {
      body,
    });
  }
}

export const csvService = new CsvService(csvStore);
