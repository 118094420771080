import { CsvReport } from "wordparrot-types";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export const CSV_REPORT_STATE_STORE = "csv_report_store";
export const KEY = "id";

export type CsvReportState = EntityState<CsvReport>;

@StoreConfig({
  idKey: KEY,
  name: CSV_REPORT_STATE_STORE,
  resettable: true,
})
export class CsvReportStore extends EntityStore<CsvReportState> {}

export const csvReportStore = new CsvReportStore();
