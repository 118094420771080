import * as style from "@dicebear/avatars-bottts-sprites";
import { createAvatar } from "@dicebear/avatars";
import React, { useEffect, useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 35,
    height: 35,
    marginRight: 10,

    "& img": {
      width: "100%",
    },
  },
}));

const DashboardAvatar = ({ id }) => {
  const classes = useStyles();
  const svgWrapperRef = useRef() as any;
  useEffect(() => {
    if (svgWrapperRef.current) {
      const img = createAvatar(style, {
        seed: id,
      });
      svgWrapperRef.current.innerHTML = img;
    }
  }, [id, svgWrapperRef]);

  return (
    <div className={classes.avatar}>
      <div ref={svgWrapperRef}></div>
    </div>
  );
};

export default DashboardAvatar;
