import { useLocation } from "react-router-dom";
import { useObservable } from "react-use";
import React, { FunctionComponent, lazy } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import DashboardSidebar from "modules/dashboard/Organisms/DashboardSidebar";
import Drawer from "@mui/material/Drawer";

import { theme$ } from "state/site/query";

export const drawerWidth = 200;
export const drawerBackgroundColor = "#f3f3f3";
export const mainWidth = drawerWidth;

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
  },

  contentShift: {
    marginLeft: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),

    [theme.breakpoints.up("lg")]: {
      marginLeft: drawerWidth,
    },
  },
}));

const DashboardDrawer: FunctionComponent<{
  drawer?: boolean;
}> = ({ drawer }) => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useObservable(theme$);

  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            width: drawerWidth,
            backgroundColor: drawerBackgroundColor,
          },
        }}
        sx={{ display: { lg: "none", xs: "block" } }}
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={drawer}
      >
        <DashboardSidebar showTopPanel />
      </Drawer>
      <Drawer
        PaperProps={{
          sx: {
            width: drawerWidth,
            backgroundColor: drawerBackgroundColor,
          },
        }}
        sx={{ display: { lg: "block", xs: "none" } }}
        className={clsx(classes.drawer, {
          [classes.contentShift]: drawer,
        })}
        variant="permanent"
        anchor="left"
        open
      >
        <DashboardSidebar />
      </Drawer>
    </>
  );
};

export default DashboardDrawer;
