import { Observable, of } from "rxjs";
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
} from "rxjs/operators";

import { PaginationResponse, PaginatorPlugin } from "@datorama/akita";
import { isNumber, random, replace, round, trim, trimStart } from "lodash-es";

import {
  ApiResponse,
  PAGINATION_COUNT_ONLY_PARAM,
  PAGINATION_PAGE_PARAM,
  PAGINATION_PER_PAGE_PARAM,
  _delete,
  get,
  post,
  put,
} from "lib/api";
import { Repository, RepositoryHistory } from "state/repository/interface";
import {
  RepositoryHistoryState,
  RepositoryState,
} from "state/session/interface";
import { breadcrumbService } from "services/Breadcrumb";
import { domainService } from "services/Domain";
import { repositoryStore } from "state/repository/store";
import { sessionQuery } from "state/session/query";
import { sessionService } from "state/session/service";

import * as apiConstants from "constants/api";
import * as repositoryConstants from "constants/repositories";

export class RepositoryService {
  fetch(config: RepositoryState): Observable<PaginationResponse<Repository>> {
    const { perPage, currentPage } = config;

    const url = `${domainService.apiRoot}/${repositoryConstants.REPOSITORIES}?${PAGINATION_PAGE_PARAM}=${currentPage}&${PAGINATION_PER_PAGE_PARAM}=${perPage}`;

    return get<PaginationResponse<Repository>>(url).pipe(
      tap((response) => {
        repositoryStore.upsertMany(response.data);
        sessionService.updateRepositories({
          total: response.total,
          lastPage: response.lastPage,
        });
        breadcrumbService.set(response.data, "repository");
      }),
    );
  }

  fetchOne(payload: { repositoryId: string }): Observable<Repository> {
    const url = `${domainService.apiRoot}/${repositoryConstants.REPOSITORIES}/${payload.repositoryId}`;

    return get<ApiResponse<Repository>>(url).pipe(
      map((response) => response.data),
      tap((repository) => {
        repositoryStore.upsertMany([repository]);
        breadcrumbService.set([repository], "repository");
      }),
    );
  }

  create(body: Repository): Observable<string> {
    return post<ApiResponse<string>>(
      `${domainService.apiRoot}/${repositoryConstants.REPOSITORIES}`,
      {
        body,
      },
    ).pipe(map((response) => response.data));
  }

  update(body: Repository): Observable<string> {
    return put<ApiResponse<string>>(
      `${domainService.apiRoot}/${repositoryConstants.REPOSITORIES}/${body.id}`,
      {
        body,
      },
    ).pipe(map((response) => response.data));
  }
}

export const repositoryService = new RepositoryService();

export class RepositoryTagService {
  create(body: { repositoryId: string; title: string }): Observable<void> {
    const { repositoryId } = body;
    return post<ApiResponse<void>>(
      `${domainService.apiRoot}/${repositoryConstants.REPOSITORIES}/${repositoryId}/${repositoryConstants.ACTION_ADD_TAG}`,
      {
        body,
      },
    ).pipe(map((response) => response.data));
  }

  update(body: {
    repositoryId: string;
    id: string;
    title: string;
    color: string;
  }): Observable<void> {
    const { repositoryId, id } = body;
    return put<ApiResponse<void>>(
      `${domainService.apiRoot}/${repositoryConstants.REPOSITORIES}/${repositoryId}/${repositoryConstants.TAGS}/${id}`,
      {
        body,
      },
    ).pipe(map((response) => response.data));
  }

  delete(body: { repositoryId: string; id: string }): Observable<void> {
    const { repositoryId, id } = body;
    return _delete<ApiResponse<void>>(
      `${domainService.apiRoot}/${repositoryConstants.REPOSITORIES}/${repositoryId}/${repositoryConstants.TAGS}/${id}`,
    ).pipe(map((response) => response.data));
  }
}

export const repositoryTagService = new RepositoryTagService();

export class RepositoryHistoryService {
  fetch(
    config: RepositoryHistoryState,
  ): Observable<PaginationResponse<RepositoryHistory>> {
    const { perPage, currentPage } = config;

    const url = `${domainService.apiRoot}/${repositoryConstants.REPOSITORY_HISTORY}?${PAGINATION_PAGE_PARAM}=${currentPage}&${PAGINATION_PER_PAGE_PARAM}=${perPage}`;

    return get<PaginationResponse<RepositoryHistory>>(url);
  }
}

export const repositoryHistoryService = new RepositoryHistoryService();
