import * as routingConstants from "lib/routing";
import { isUUID } from "lib/functions";
import isFunction from "lodash-es/isFunction";

export type BreadcrumbType = {
  title: string;
  replacement?: string;
  entityType: string;
};

class BreadcrumbService {
  map: Record<string, BreadcrumbType> = {};

  get(id: string): string {
    const match = this.map[id];

    if (!match) {
      return "-";
    }

    if (match.replacement && this.map[match.replacement]) {
      return this.map[match.replacement].title;
    }

    return match.title;
  }

  set(
    entities: any[],
    entityType: string,
    idProperty = "id",
    render?: (entity: any) => string,
  ): void {
    entities.forEach((entity) => {
      if (idProperty === "id") {
        this.map[entity[idProperty]] = {
          title: entity.title,
          entityType,
        };
      } else if (entity.id && entity[idProperty]) {
        if (isUUID(entity[idProperty])) {
          this.map[entity.id] = {
            title: "-",
            replacement: entity[idProperty],
            entityType,
          };
        } else {
          this.map[entity.id] = {
            title: isFunction(render) ? render(entity) : entity[idProperty],
            entityType,
          };
        }
      }
    });
  }
}

export const breadcrumbService = new BreadcrumbService();
