import { EntityState, createEntityStore } from "@datorama/akita";
import { Image } from "state/image/interface";

export const IMAGE_STATE_STORE = "image_store";
export const KEY = "id";

export type ImageState = EntityState<Image>;

export const imageStore = createEntityStore<ImageState>(
  {},
  { idKey: KEY, name: IMAGE_STATE_STORE, resettable: true },
);
