import { Image } from "wordparrot-types";
import { PaginationResponse } from "@datorama/akita";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { firstValueFrom, of } from "rxjs";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { MutationOptions, QueryOptions } from "lib/request";
import { imageService } from "state/image/service";

export interface ImageListQuery {
  perPage: number;
  currentPage: number;
  total: number;
  lastPage: number;
  countOnly: boolean;
  repositoryId: string;
  ids?: string[];
}

export const useImages = (
  payload: ImageListQuery,
  options?: QueryOptions<
    ["imageService.fetch", typeof payload],
    PaginationResponse<Image>
  >,
) => {
  return useQuery(
    ["imageService.fetch", payload],
    () => firstValueFrom(imageService.fetch(payload)),
    {
      ...options,
      enabled: !!(Array.isArray(payload.ids) ? payload.ids.length > 0 : true),
    },
  );
};

export const useCreateImage = (
  options: MutationOptions<
    {
      formData: FormData;
      title: string;
      subtitle: string;
      owner: string;
    },
    Image
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload) => {
      return firstValueFrom(
        imageService.uploadMultipart(payload.formData).pipe(
          switchMap((response) => {
            return imageService.post({
              ...response,
              title: payload.title,
              subtitle: payload.subtitle,
              owner: payload.owner,
            });
          }),
        ),
      );
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        void queryClient.invalidateQueries("imageService.fetch");
        await options.onSuccess?.(data, variables, context);
      },
    },
  );
};

export const useDeleteImages = (
  options: MutationOptions<{ ids: string[] }, ({ id: string } | null)[]>,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload) => {
      if (!payload.ids.length) {
        return Promise.resolve([]);
      }

      return Promise.all(
        payload.ids.map((id) => {
          return firstValueFrom(
            imageService.delete(id).pipe(catchError(() => of(null))),
          );
        }),
      );
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        void queryClient.invalidateQueries("imageService.fetch");
        await options.onSuccess?.(data, variables, context);
      },
    },
  );
};
