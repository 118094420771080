import { ReactElement, lazy } from "react";

import { Redirect } from "react-router";

import * as routingConstants from "lib/routing";

const AppProjectList = lazy(() => import("modules/app/Pages/AppProjectList"));
const AppSearch = lazy(() => import("modules/app/Pages/AppSearch/AppSearch"));
const AppProjectStats = lazy(() => import("modules/app/Pages/AppProjectStats"));
const PluginDisplay = lazy(() => import("modules/plugin/Pages/PluginDisplay"));
const AppBlueprintOneClick = lazy(
  () => import("modules/app/Pages/AppBlueprintOneClick"),
);
const PipelineMain = lazy(() => import("modules/pipeline/Pages/PipelineMain"));
const PipelineJobMain = lazy(
  () => import("modules/pipeline/Pages/PipelineJobMain"),
);
const BlueprintInstallationUnpack = lazy(
  () => import("modules/blueprint/Pages/BlueprintInstallationUnpack"),
);
const ProjectDisplay = lazy(
  () => import("modules/project/Pages/ProjectDisplay"),
);
const PipelineProgressVisual = lazy(
  () => import("modules/pipeline/Pages/PipelineProgressVisual"),
);

export const routes: {
  elem: ReactElement;
  path: string;
  onMobile?: ReactElement;
  icon?: ReactElement;
}[] = [
  {
    path: routingConstants.ROUTE_APP_BLUEPRINT_INSTALLATIONS_UNPACK,
    elem: <BlueprintInstallationUnpack />,
  },
  {
    path: routingConstants.ROUTE_APP_PIPELINE_JOBS_MAIN,
    elem: <PipelineJobMain />,
  },
  {
    path: routingConstants.ROUTE_APP_PIPELINES_PROGRESS_VISUAL,
    elem: <PipelineProgressVisual />,
  },
  {
    path: routingConstants.ROUTE_APP_PIPELINES_MAIN,
    elem: <PipelineMain />,
  },
  {
    path: routingConstants.ROUTE_APP_BLUEPRINT_INSTALL_ONE_CLICK,
    elem: <AppBlueprintOneClick />,
  },
  {
    path: routingConstants.ROUTE_APP_PROJECTS_STATS,
    elem: <AppProjectStats />,
  },
  {
    path: routingConstants.ROUTE_APP_PLUGIN_DISPLAY,
    elem: <PluginDisplay />,
  },
  {
    path: routingConstants.ROUTE_APP_PROJECTS_DISPLAY,
    elem: <ProjectDisplay />,
  },
  {
    path: routingConstants.ROUTE_APP_PROJECTS_LIST,
    elem: <AppProjectList />,
  },
  {
    path: routingConstants.ROUTE_APP_SEARCH,
    elem: <AppSearch />,
  },
  {
    path: routingConstants.ROUTE_APP_PREFIX,
    elem: <Redirect to={routingConstants.ROUTE_APP_SEARCH} />,
  },
];
