import { ACL } from "wordparrot-types";

import { Category, Subcategory } from "../category/interface";
import { Entry, EntryItem } from "../entry/interface";
import { Profile } from "state/user/interface";
import { Role } from "../role/interface";

export const SESSION_STORE = "session_store";

export interface AbstractPaginationState {
  currentPage: number;
  perPage: number;
  total: number;
  lastPage: number;
  countOnly: boolean;
  // Allow input timestamp to force refresh even when pagination params have not changed.
  timestamp?: number;
}

export interface UiState {
  pageTitle: string;
  mainView: string;
  loadingSearch: boolean;
  responseReceived: boolean;
  showAlternative: boolean;
  highlightError: boolean;
  mostRecentUpdate: string;
  dateScrolled: string | undefined;
  entryScrolled: string | undefined;
  // trackerIndex: number;
  // trackerPerPage: number;
  // disableSmoothScroll: boolean;
  // displayTracker: boolean;
  dialog: string;
  bookmarks: string[];
  bookmarksRemoved: string[];
}

export const InitializeUiState: () => UiState = () => ({
  pageTitle: "",
  mainView: "feed",
  loadingSearch: false,
  responseReceived: false,
  showAlternative: false,
  highlightError: false,
  mostRecentUpdate: "",
  dateScrolled: "",
  entryScrolled: "",
  dialog: "",
  bookmarks: [],
  bookmarksRemoved: [],
});

export interface DashboardState {
  subtitle: string;
}

export const InitializeDashboardState: () => DashboardState = () => ({
  subtitle: "",
});

export interface MainPaginationState extends AbstractPaginationState {
  siteId?: string;
  localSiteId: string;
  currentCategory: string | null;
  currentSubcategory: string | null;
  currentEntry: string | null;
  currentEntryItem: string | null;

  timestamp: number;
  text: string;
  type: string;
}

export const InitializeMainPaginationState: () => MainPaginationState = () => ({
  currentPage: 1,
  perPage: 25,
  total: 0,
  lastPage: 1,
  countOnly: false,
  siteId: "",
  localSiteId: "",
  currentCategory: null,
  currentSubcategory: null,
  currentEntry: null,
  currentEntryItem: null,
  timestamp: 0,
  text: "",
  type: "",
});

export interface SettingsState {
  currentCategory: Category | null;
  currentSubcategory: Subcategory | null;
  entry: Entry | null;
  entryItem: EntryItem | null;
  ignoreUrlWarning: boolean;
  status: string;
}

export const InitializeSettingsState: () => SettingsState = () => ({
  currentCategory: null,
  currentSubcategory: null,
  entry: null,
  entryItem: null,
  ignoreUrlWarning: false,
  status: "",
});

export interface ContactState {
  currentPage: number;
  perPage: number;
  text: string;
  currentType: string;
}

export const InitializeContactState: () => ContactState = () => ({
  currentType: "",
  currentPage: 0,
  perPage: 10,
  text: "",
});

export interface AuthState {
  isLoggedIn: boolean;
  acl: ACL[];
  hasRequestedAuth: boolean;
  hasReceivedAuth: boolean;
}

export const InitializeAuthState: () => AuthState = () => ({
  authScheme: "",
  isLoggedIn: false,
  acl: [],
  hasRequestedAuth: false,
  hasReceivedAuth: false,
});

export interface ProfileState {
  id: string;
  hubUserId: string;
  hasHubToken: boolean;
  name: string;
  email: string;
  permissions: string[];
  roles: Role[];
  profile?: Profile;
  createdAt: string;
  updatedAt: string;
}

export const InitializeProfileState: () => ProfileState = () => ({
  id: "",
  hubUserId: "",
  hasHubToken: false,
  name: "",
  email: "",
  permissions: [],
  roles: [],
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
});

export interface EntryItemMeta {
  entry: Entry;
  entryItem: EntryItem;
  date: string;
}

export interface DownloadHistoryState {
  currentEntryItem: EntryItem | null;
  entryItemMeta: EntryItemMeta[];
  showHistory: boolean;
  showBar: boolean;
}

export const InitializeDownloadHistoryState: () => DownloadHistoryState =
  () => ({
    currentEntryItem: null,
    entryItemMeta: [],
    showHistory: false,
    showBar: false,
  });

export interface ModalState {
  entry: Entry | null;
  entryItem: EntryItem | null;
  type: "entryItem" | "entry" | null;
}

export const InitializeModalState: () => ModalState = () => ({
  entry: null,
  entryItem: null,
  type: null,
});

export interface ImageState extends AbstractPaginationState {
  ids?: string[];
  repositoryId: string;
}

export const InitializeImageState: () => ImageState = () => ({
  currentPage: 1,
  perPage: 10,
  total: 0,
  lastPage: 0,
  countOnly: false,
  ids: [],
  repositoryId: "",
});

export type RepositoryState = AbstractPaginationState;

export const InitializeRepositoryState: () => RepositoryState = () => ({
  currentPage: 1,
  perPage: 25,
  total: 0,
  lastPage: 0,
  countOnly: false,
});

export interface RepositoryHistoryState extends AbstractPaginationState {
  repositoryId: string;
}

export const InitializeRepositoryHistoryState: () => RepositoryHistoryState =
  () => ({
    repositoryId: "",
    pipelineFlowId: "",
    currentPage: 1,
    perPage: 5,
    total: 0,
    lastPage: 0,
    countOnly: false,
  });

export interface RepositoryItemState extends AbstractPaginationState {
  repositoryId: string;
  repositoryTagIds: string[];
  type: string;
}

export const InitializeRepositoryItemState: () => RepositoryItemState = () => ({
  repositoryId: "",
  pipelineFlowId: "",
  repositoryTagIds: [],
  currentPage: 1,
  perPage: 10,
  total: 0,
  lastPage: 0,
  countOnly: false,
  type: "",
});

export interface CredentialState extends AbstractPaginationState {
  search: string;
  workingOnly: boolean | undefined;
}

export const InitializeCredentialState: (config: {
  workingOnly: boolean;
}) => CredentialState = (config) => ({
  currentPage: 1,
  perPage: 10,
  total: 0,
  lastPage: 0,
  countOnly: false,
  search: "",
  ...config,
});

export interface CredentialFormState extends AbstractPaginationState {
  active: boolean;
}

export const InitializeCredentialFormState: () => CredentialFormState = (
  active = true,
) => ({
  currentPage: 1,
  perPage: 10,
  total: 0,
  lastPage: 0,
  countOnly: false,
  active,
});

export interface PipelineState extends AbstractPaginationState {
  pipelineId: string;
  pipelineNodeId: string;
  pipelineGroupId: string;
  search: string;
  queueStatus: string;
  includeNodes?: boolean;
  includeCredentials?: boolean;
  byProject?: boolean;
}

export const InitializePipelineState: () => PipelineState = () => ({
  pipelineId: "",
  pipelineNodeId: "",
  pipelineGroupId: "",
  currentPage: 1,
  perPage: 50,
  total: 0,
  lastPage: 0,
  countOnly: false,
  search: "",
  queueStatus: "",
  includeNodes: false,
});

export interface PipelineJobState extends AbstractPaginationState {
  pipelineId?: string;
  pipelineJobId?: string;
  pipelineGroupId?: string;
  days?: number;
  byProject?: boolean;
}

export const InitializePipelineJobState: (
  config: Record<string, any>,
) => PipelineJobState = (config) => ({
  pipelineId: config.pipelineId,
  pipelineJobId: "",
  currentPage: 1,
  perPage: 10,
  total: 0,
  lastPage: 1,
  countOnly: false,
  days: config.days,
  ...config,
});

export interface PipelineFlowState extends AbstractPaginationState {
  repositoryId?: string;
}

export const InitializePipelineFlowState: () => PipelineFlowState = () => ({
  repositoryId: "",
  currentPage: 1,
  perPage: 5,
  total: 0,
  lastPage: 1,
  countOnly: false,
});

export interface FeaturedGroupState extends AbstractPaginationState {
  self?: boolean;
  search?: string;
  subscribed?: boolean;
  threads?: boolean;
}

export const InitializeSelfFeaturedGroupState: () => FeaturedGroupState =
  () => ({
    currentPage: 1,
    perPage: 10,
    total: 0,
    lastPage: 1,
    self: true,
    threads: true,
    subscribed: false,
    countOnly: false,
  });

export const InitializeSubscribedFeaturedGroupState: () => FeaturedGroupState =
  () => ({
    currentPage: 1,
    perPage: 10,
    total: 0,
    lastPage: 1,
    self: false,
    subscribed: true,
    countOnly: false,
  });

export const InitializeGeneralFeaturedGroupState: () => FeaturedGroupState =
  () => ({
    currentPage: 1,
    perPage: 12,
    total: 0,
    lastPage: 1,
    self: false,
    subscribed: false,
    countOnly: false,
  });

export interface FeaturedThreadState extends AbstractPaginationState {
  featuredGroupId: string;
  search?: string;
  self?: boolean;
}

export const InitializeFeaturedThreadState: () => FeaturedThreadState = () => ({
  featuredGroupId: "",
  featuredThreadId: "",
  currentPage: 1,
  perPage: 10,
  total: 0,
  lastPage: 1,
  self: false,
  countOnly: false,
});

export interface FeaturedThreadReleaseState extends AbstractPaginationState {
  featuredGroupId: string;
  featuredThreadId: string;
  self?: boolean;
}

export const InitializeFeaturedThreadReleaseState: () => FeaturedThreadReleaseState =
  () => ({
    featuredGroupId: "",
    featuredThreadId: "",
    currentPage: 1,
    perPage: 10,
    total: 0,
    lastPage: 1,
    self: false,
    countOnly: false,
  });

export interface FeaturedThreadItemState extends AbstractPaginationState {
  featuredGroupId: string;
  featuredThreadId: string;
  featuredThreadReleaseId: string;
  self?: boolean;
}

export const InitializeFeaturedThreadItemState: () => FeaturedThreadItemState =
  () => ({
    featuredGroupId: "",
    featuredThreadId: "",
    featuredThreadReleaseId: "",
    currentPage: 1,
    perPage: 10,
    total: 0,
    lastPage: 1,
    self: false,
    countOnly: false,
  });

export type FeaturedInvitationState = AbstractPaginationState;

export const InitializeFeaturedInvitationState: () => FeaturedInvitationState =
  () => ({
    currentPage: 1,
    perPage: 10,
    total: 0,
    lastPage: 1,
    countOnly: false,
  });

export type UserState = AbstractPaginationState;

export const InitializeUserState: () => UserState = () => ({
  currentPage: 1,
  perPage: 25,
  total: 0,
  lastPage: 0,
  countOnly: false,
});

export interface UpstreamFeaturedThreadState extends AbstractPaginationState {
  pipelineId: string;
  search: string;
}

export const InitializeUpstreamFeaturedThreadState: () => UpstreamFeaturedThreadState =
  () => ({
    pipelineId: "",
    currentPage: 1,
    perPage: 10,
    total: 0,
    lastPage: 0,
    countOnly: false,
    search: "",
  });

export interface UpstreamPipelineNodeState extends AbstractPaginationState {
  pipelineId: string;
}

export interface DownstreamPipelineState extends AbstractPaginationState {
  pipelineId: string;
  pipelineNodeId: string;
}

export interface TileState extends AbstractPaginationState {
  tileId: string;
  search: string;
  subscription: boolean;
}

export const InitializeTileState: () => TileState = () => ({
  tileId: "",
  currentPage: 1,
  perPage: 10,
  total: 0,
  lastPage: 0,
  countOnly: false,
  search: "",
  subscription: false,
});

export interface ChartState extends AbstractPaginationState {
  featuredGroupId: string;
  featuredThreadId: string;
  repositoryId: string;
  siteId: string;
  entryId: string;
  type: string;
}

export const InitializeChartState: () => ChartState = () => ({
  featuredGroupId: "",
  featuredThreadId: "",
  repositoryId: "",
  siteId: "",
  entryId: "",
  type: "",
  currentPage: 1,
  perPage: 10,
  total: 0,
  lastPage: 0,
  countOnly: false,
  search: "",
});

export interface PipelineGroupState extends AbstractPaginationState {
  siteId: string;
}

export const InitializePipelineGroupState: () => PipelineGroupState = () => ({
  siteId: "",
  currentPage: 1,
  perPage: 1000,
  total: 0,
  lastPage: 0,
  countOnly: false,
  search: "",
});

export type BannerState = AbstractPaginationState;

export const InitializeBannerState: () => BannerState = () => ({
  currentPage: 1,
  perPage: 10,
  total: 0,
  lastPage: 0,
  countOnly: false,
  search: "",
});

export interface TrendingState extends AbstractPaginationState {
  categorySlug: string;
  subcategorySlug: string;
}

export const InitializeTrendingState: () => TrendingState = () => ({
  siteId: "",
  currentPage: 1,
  perPage: 10,
  total: 0,
  lastPage: 0,
  countOnly: false,
  categorySlug: "",
  subcategorySlug: "",
});

export interface HubPluginState extends AbstractPaginationState {
  search: string;
  categorySlug: string;
  subcategorySlug: string;
}

export const InitializeHubPluginState: () => HubPluginState = () => ({
  currentPage: 1,
  perPage: 10,
  total: 0,
  lastPage: 0,
  countOnly: false,
  search: "",
  categorySlug: "",
  subcategorySlug: "",
});

export interface ListenerState extends AbstractPaginationState {
  pipelineId: string;
  pipelineGroupId: string;
}

export interface ListenerJobState extends AbstractPaginationState {
  listenerId: string;
}

export const InitializeListenerJobState: (
  config: Record<string, any>,
) => ListenerJobState = (config) => ({
  currentPage: 1,
  perPage: 5,
  total: 0,
  lastPage: 0,
  countOnly: false,
  listenerId: "",
  ...config,
});

export interface RepositoryFileState extends AbstractPaginationState {
  repositoryId: string;
  pipelineFlowId?: string;
  repositoryTagIds?: string[];
  type?: string;
}

export const InitializeRepositoryFileState: () => RepositoryFileState = () => ({
  repositoryId: "",
  pipelineFlowId: "",
  repositoryTagIds: [],
  currentPage: 1,
  perPage: 10,
  total: 0,
  lastPage: 0,
  countOnly: false,
  type: "",
});

export interface WebhookState extends AbstractPaginationState {
  pipelineId: string;
  pipelineGroupId: string;
}

export interface CsvState extends AbstractPaginationState {
  repositoryId: string;
}

export interface CsvReportState extends AbstractPaginationState {
  repositoryId: string;
}

export interface CsvReportSavedState extends AbstractPaginationState {
  csvReportId: string;
}

export interface DataStoreState extends AbstractPaginationState {
  repositoryId: string;
}
export interface DataStoreVersionState extends AbstractPaginationState {
  dataStoreId: string;
}

export interface PromptState extends AbstractPaginationState {
  pipelineGroupId: string;
  user?;
}

export interface PromptDefaultRecipientState extends AbstractPaginationState {
  promptId: string;
}

export interface PromptDownstreamState extends AbstractPaginationState {
  promptId: string;
}

export interface PromptInstanceState extends AbstractPaginationState {
  promptId: string;
}

export interface PromptRecipientState extends AbstractPaginationState {
  promptId: string;
  promptInstanceId: string;
  startTime?: number;
  endTime?: number;
}
