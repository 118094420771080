import trimStart from "lodash-es/trimStart";

export class UrlService {
  private URL_ROOT = `/dashboard`;

  get urlRoot(): string {
    return `/${trimStart(this.URL_ROOT, "/")}`;
  }

  set urlRoot(str: string) {
    this.URL_ROOT = str;
  }
}

export const urlService = new UrlService();
