"use client";

import {
    FunctionComponent,
    ReactNode,
    Suspense,
    useEffect,
    useState,
  } from "react";
  import { QueryClient, QueryClientProvider } from "react-query";
  import { BrowserRouter as Router } from "react-router-dom";
  import {
    StyledEngineProvider,
    Theme,
    ThemeProvider,
  } from "@mui/material/styles";
  import { useObservable } from "react-use";
  import CssBaseline from "@mui/material/CssBaseline";

  import { banner } from "banner";
  import useCustom from "theme/useCustom";

  import { theme$ } from "state/site/query";

  import Loading from "modules/_common/pages/Loading";
  import ScrollToTop from "modules/_common/atoms/ScrollToTop";

  import "./globals.css";

  declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
  }
  // Temporary workaround for react-query
  declare module "react-query/types/react/QueryClientProvider" {
    interface QueryClientProviderProps {
      children?: ReactNode;
    }
  }

  interface ClientOnlyProps {
    children?: ReactNode;
  }

  const ClientOnly: FunctionComponent<ClientOnlyProps> = ({ children }) => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
      setIsClient(true);
    }, []);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{isClient ? children : ""}</>;
  };

  // Create a client
  const queryClient = new QueryClient();

  const AppWrapper = ({ children }) => {
    const customTheme = useObservable(theme$);
    const myTheme = useCustom(customTheme);

    useEffect(() => {
      console.log(banner);
    }, []);

    return (
      <ClientOnly>
        {typeof window === "undefined" ? null : (
          <CssBaseline>
            <Router>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={myTheme}>
                  <QueryClientProvider client={queryClient}>
                    <Suspense fallback={<Loading />}>
                      <ScrollToTop />
                      {children}
                    </Suspense>
                  </QueryClientProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </Router>
          </CssBaseline>
        )}
      </ClientOnly>
    );
  };

  export default AppWrapper;
