import { FieldMetaProps, useField, useFormikContext } from "formik";
import { Image } from "wordparrot-types";
import { useCallback, useMemo } from "react";
import isString from "lodash-es/isString";
import makeStyles from "@mui/styles/makeStyles";

import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { isUUID } from "lib/functions";
import { useImages } from "api/image";

import { Typography } from "@mui/material";
import SelectOptionImage from "./SelectOptionImage";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
}));

const renderHelperText = (errorStatus, input) => {
  if (!errorStatus) {
    return null;
  }
  if (isString(input)) {
    return <FormHelperText>{input}</FormHelperText>;
  }
  return <FormHelperText component={input} />;
};

const getMatchingImageUrl = (uuid: string, images: Image[]): string => {
  if (!images.length) return "";

  const matchingImage = images.find((image) => {
    return image.id === uuid;
  });

  return matchingImage?.url || "";
};

const FormikSelect = ({
  name,
  label,
  helperText,
  options,
  ...optionalProps
}) => {
  const { multiple, readonly, children, optionsHaveImage, ...remainingProps } =
    optionalProps;

  const classes = useStyles();

  const [field, meta, helpers] = useField({
    ...remainingProps,
    name,
  });

  const { setFieldValue } = useFormikContext();

  const {
    error,
    touched,
  }: FieldMetaProps<{ error: string; touched: boolean }> = meta;

  const value: string | string[] = useMemo(() => {
    if (multiple && !field.value) {
      return [];
    }
    if (
      (multiple && !Array.isArray(field.value)) ||
      (remainingProps.SelectProps?.multiple && !Array.isArray(field.value))
    ) {
      if (field.value) {
        return [field.value];
      }
      return [];
    }
    return field.value;
  }, [field.value, multiple, remainingProps]);

  const clearField = useCallback(() => {
    void helpers.setValue(multiple ? [] : "");
  }, [helpers]);

  const handleChange = useCallback(
    (evt) => {
      setFieldValue(name, evt.target.value, true);
    },
    [name, setFieldValue],
  );

  // Fetch images if optionsHaveImage is true
  const imageIdsToSearch: string[] = useMemo(() => {
    if (!optionsHaveImage) return [];
    return options
      .map((option) => option.value)
      .filter((value) => isUUID(value));
  }, []);

  const { data: paginationResponse } = useImages(
    {
      currentPage: 1,
      perPage: 100,
      total: 0,
      lastPage: 0,
      ids: imageIdsToSearch,
      countOnly: false,
      repositoryId: "",
    },
    {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <FormControl className={classes.formControl} error={!touched && !!error}>
      <TextField
        value={value || (multiple ? [] : "")}
        onChange={handleChange}
        inputProps={{
          name: field.name,
          id: field.name,
        }}
        SelectProps={
          remainingProps.SelectProps || {
            readOnly: readonly || false,
            multiple: multiple || false,
          }
        }
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: meta.value?.length ? (
            <InputAdornment position="end" sx={{ marginRight: "15px" }}>
              <IconButton size="small" onClick={clearField}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          ),
        }}
        select
        label={label}
        variant="filled"
      >
        {children ||
          options?.map((option, i) => {
            const { id, value, label, name } = option;
            if (name) {
              return <ListSubheader key={id || i}>{name}</ListSubheader>;
            }
            return (
              <MenuItem key={id || i} value={value}>
                <Box display="flex" alignItems="center">
                  {optionsHaveImage && (
                    <Box sx={{ marginRight: "15px" }}>
                      <SelectOptionImage
                        url={getMatchingImageUrl(
                          value,
                          paginationResponse?.data || [],
                        )}
                      />
                    </Box>
                  )}
                  <Box>
                    <Typography>{label || value}</Typography>
                    {option.description && (
                      <Typography variant="subtitle2" sx={{ opacity: ".5" }}>
                        {option.description}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </MenuItem>
            );
          })}
      </TextField>
      {renderHelperText(!!touched && !!error, error)}
    </FormControl>
  );
};

export default FormikSelect;
