export const FEATURED_GROUPS = "featured-groups";
export const THREADS = "threads";
export const RELEASES = "releases";
export const ITEMS = "items";
export const IMAGES = "images";
export const FEATURED_IMAGES = "featured-images";
export const SUBSCRIBE = "subscribe";
export const SUBSCRIBE_TOTAL = "subscribe-total";
export const UNSUBSCRIBE = "unsubscribe";
export const SUBSCRIBED = "subscribed";
export const SELF = "self";
export const SEARCH = "search";
export const INVITATIONS = "invitations";
export const UPDATE = "update";
export const DISPLAY = "display";
export const SAMPLE = "sample";

export const THREAD_PREFERENCE_TYPE_EMAIL_FORMATTED = "email_formatted";
export const THREAD_PREFERENCE_TYPE_EMAIL_RAW = "email_raw";
export const THREAD_PREFERENCE_TYPE_WEB = "web";
export const THREAD_PREFERENCE_TYPE_PIPELINE = "pipeline";
export const THREAD_PREFERENCE_TYPE_REPOSITORY = "repository";
export const THREAD_PREFERENCE_TYPE_PUSH_NOTIFICATION = "push_notification";
export const THREAD_PREFERENCE_TYPE_NONE = "none";

export const THREAD_RELEASE_STATUS_PUBLISH = "publish";
export const THREAD_RELEASE_STATUS_DRAFT = "draft";
export const THREAD_RELEASE_STATUS_PRIVATE = "private";

export const THREAD_PUSH_STATUS_PUBLISH = "publish";
export const THREAD_PUSH_STATUS_DRAFT = "draft";
export const THREAD_PUSH_STATUS_PRIVATE = "private";

export const SUBSCRIPTION_SCHEME_OPEN = "open";
export const SUBSCRIPTION_SCHEME_INVITE = "invite";
export const SUBSCRIPTION_SCHEME_PRIVATE = "private";
export const SUBSCRIPTION_SCHEME_PAYMENT_SINGLE = "payment-single";
export const SUBSCRIPTION_SCHEME_PAYMENT_RECURRING = "recurring";

export const INVITATION_STATUS_PENDING = "pending";
export const INVITATION_STATUS_ACCEPTED = "accepted";
export const INVITATION_STATUS_REJECTED = "rejected";

export const FEATURED_GROUP_PROVIDER_MODE_NORMAL = "normal";
export const FEATURED_GROUP_PROVIDER_MODE_PUSH_NOTIFICATION =
  "push_notification";

export const FEATURED_GROUP_PROVIDER_ACTION_MANUAL = "manual";
export const FEATURED_GROUP_PROVIDER_ACTION_TRANSFORM = "transform";
