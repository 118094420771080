export const REPOSITORIES = "repositories";
export const ITEMS = "items";
export const FILES = "files";
export const BY_ID = "by-id";
export const JOBS = "jobs";
export const REPOSITORY_HISTORY = "repository-history";
export const TAGS = "tags";

export const REPOSITORY_OPERATION_WRITE = "write";
export const REPOSITORY_OPERATION_DELETE = "delete";
export const REPOSITORY_OPERATION_UPDATE = "update";

export const ACTION_ADD_TAG = "add-tag";
export const ACTION_DOWNLOAD = "download";
export const ACTION_UPLOAD = "upload";
export const ACTION_UPDATE_TITLE = "update-title";
export const ACTION_DELETE = "delete";
