import { snack$ } from "modules/_common/atoms/Snackbar";

export class SnackbarService {
  next(evt: {
    show: boolean;
    message: string;
    type: "success" | "error" | "warning" | "info" | "loading" | "primary";
  }) {
    snack$.next(evt);
  }
}

export const snackbarService = new SnackbarService();
