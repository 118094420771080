import { Plugin, Project } from "wordparrot-types";
import find from "lodash-es/find";
import get from "lodash-es/get";
import random from "lodash-es/random";
import uniqBy from "lodash-es/uniqBy";

declare let window;

export function convertToRgba(hex: string, opacity: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r},${g},${b},${opacity})`;
}

export function getCookie(c_name: string) {
  if (typeof window === "undefined") {
    return "";
  }

  let c_value: string = " " + window.document.cookie;
  let c_start = c_value.indexOf(" " + c_name + "=");
  if (c_start === -1) {
    c_value = "";
  } else {
    c_start = c_value.indexOf("=", c_start) + 1;
    let c_end = c_value.indexOf(";", c_start);
    if (c_end === -1) {
      c_end = c_value.length;
    }
    c_value = unescape(c_value.substring(c_start, c_end));
  }
  return c_value;
}

export function deleteAllCookies() {
  if (typeof window === "undefined") {
    return "";
  }

  const res = window.document.cookie;
  const multiple = res.split(";");
  for (let i = 0; i < multiple.length; i++) {
    const key = multiple[i].split("=");
    window.document.cookie =
      key[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
  }
}

export function getLastPage(arr: any[], perPage: number): number {
  return Math.floor((arr.length - 1) / perPage + 1);
}

export function toNormalCase(str: string): string {
  return (
    str
      // insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
}

export function capitalizeId(str: string): string {
  return str
    .split(" ")
    .map((fragment) =>
      fragment === "id" || fragment === "Id"
        ? fragment.toUpperCase()
        : fragment,
    )
    .join(" ");
}

function capSplit(str: string): string {
  return str
    .replace(
      /([A-Z][a-z])|([a-z][A-Z])|(\d[a-zA-Z])/g,
      function (g, m1, m2, m3) {
        return m1 ? " " + m1 : m2 ? m2[0] + " " + m2[1] : m3[0] + " " + m3[1];
      },
    )
    .replace(/^[a-z]/g, function (g) {
      return g.toUpperCase();
    });
}

export function camelToTitle(str: string | undefined): string {
  if (!str) {
    return "";
  }

  return capSplit(str);
}

export function snakeToTitle(str: string | undefined): string {
  if (!str) {
    return "";
  }

  return toTitleCase(str.split("_").join(" "));
}

export function toTitleCase(str: string | undefined): string {
  if (!str) {
    return "";
  }
  str = str.split("_").join(" ");
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function isIdProperty(str: string | undefined): boolean {
  if (!str) {
    return false;
  }
  return str.substring(str.length - 2).toLowerCase() === "id";
}

export function toSnakeCase(str: string | undefined): string {
  if (!str) {
    return "";
  }
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function commaSeparated(
  str: string | undefined,
  divider: string,
): string {
  if (!str) {
    return "";
  }
  return str.split(",").join(divider);
}

export function shallowCompare(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

export function largeNumber(number: number): string {
  if (number >= 1000000) {
    if (number >= 10000000) {
      return `${(number / 1000000).toFixed(0)}m`;
    } else {
      return `${(number / 1000000).toFixed(1)}m`;
    }
  }

  if (number >= 1000) {
    if (number >= 10000) {
      return `${(number / 1000).toFixed(0)}k`;
    } else {
      return `${(number / 1000).toFixed(1)}k`;
    }
  }

  return number.toFixed(0);
}

// const handleDownload = (fileName, rowTemplate, dataSet) => {
//   const headLabel = rowTemplate.map((obj) => obj.label)
//   const body = dataSet.map((rowData) =>
//     rowTemplate.map((headItem) => {
//       const property = _get(rowData, headItem.id, '')
//       if (headItem.downloadRender) return headItem.downloadRender(property)
//       if (headItem.render) return headItem.render(rowData)
//       return typeof property === 'string' && property.includes(',')
//         ? `"${property}"`
//         : property
//     })
//   )

//   const CSV = [headLabel, ...body].join('\r\n')
//   const csvFile = new Blob(['\uFEFF' + CSV], {
//     type: 'text/csv; charset=utf-8',
//   })
//   const CSVFileName = `${fileName}.csv`
//   if (window.navigator.msSaveOrOpenBlob) {
//     window.navigator.msSaveOrOpenBlob(csvFile, CSVFileName)
//   } else {
//     // FYI: https://stackoverflow.com/a/32226068
//     // in firefox and edge, the element must be existed in document.
//     const link = document.createElement('a')
//     link.href = URL.createObjectURL(csvFile)
//     link.download = CSVFileName
//     document.body.appendChild(link)
//     link.click()
//     link.remove()
//   }
// }

function getRandomIndex(arrayLength) {
  return random(0, arrayLength - 1);
}

export function randomChoice(arr): unknown {
  const randomIndex = getRandomIndex(arr.length);
  return arr[randomIndex];
}

export const createCSVHeaderField = (id, label, render) => ({
  id,
  label,
  render,
});
export const downloadCSV = (fileName, rowTemplate, dataSet, postRender) => {
  const headLabel = rowTemplate.map((obj) => obj.label);
  const body = dataSet.map((obj) =>
    rowTemplate.map((headItem) => {
      const content = get(obj, headItem.id, "");
      return headItem.render
        ? headItem.render(content, obj)
        : typeof content === "string" && content.includes(",")
          ? `"${content}"`
          : content;
    }),
  );

  const CSV = (
    postRender ? postRender([headLabel, ...body]) : [headLabel, ...body]
  ).join("\r\n");
  const csvFile = new Blob([`\uFEFF${CSV}`], {
    type: "text/csv; charset=utf-8",
  });
  const CSVFileName = `${fileName}.csv`;
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(csvFile, CSVFileName);
  } else {
    // FYI: https://stackoverflow.com/a/32226068
    // in firefox and edge, the element must be existed in document.
    const link = document.createElement("a");
    link.href = URL.createObjectURL(csvFile);
    link.download = CSVFileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

export const getMatchingPlugins = (
  plugins: Plugin[],
  project: Project,
): Plugin[] => {
  const matchingPlugins: Plugin[] = [];

  project.prompts?.forEach((prompt) => {
    const [author, name] = prompt.provider.split(".") || [];
    const matchingPlugin = find(plugins, (plugin) => {
      return plugin.author === author && plugin.name === name;
    });
    if (matchingPlugin) {
      matchingPlugins.push(matchingPlugin);
    }
  });

  project.listeners?.forEach((listener) => {
    const [author, name] = listener.provider.split(".") || [];
    const matchingPlugin = find(plugins, (plugin) => {
      return plugin.author === author && plugin.name === name;
    });
    if (matchingPlugin) {
      matchingPlugins.push(matchingPlugin);
    }
  });

  project.webhooks?.forEach((webhook) => {
    const [author, name] = webhook.provider.split(".") || [];
    const matchingPlugin = find(plugins, (plugin) => {
      return plugin.author === author && plugin.name === name;
    });
    if (matchingPlugin) {
      matchingPlugins.push(matchingPlugin);
    }
  });

  project.pipelines.forEach((pipeline) => {
    pipeline.nodes.forEach((node) => {
      const [author, name] = node.provider.split(".") || [];
      const matchingPlugin = find(plugins, (plugin) => {
        return plugin.author === author && plugin.name === name;
      });
      if (matchingPlugin) {
        matchingPlugins.push(matchingPlugin);
      }
    });
  });

  return uniqBy(matchingPlugins, (plugin) => plugin.hubPluginId);
};

export const isUUID = (str: string): boolean => {
  return new RegExp(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
  ).test(str);
};
